import { Grid, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import CustomDialog from 'src/components/custom-dialog';
import ControlledTextField from 'src/components/controlled-input/TextField';
import useMessage from 'src/hooks/useMessage';
import InputLabel from 'src/components/input-label';
import useTranslate from 'src/hooks/useTranslate';
import Spinner, { useSpinner } from '../spinner';
import DepartmentAPI from 'src/api/department';

const DepartmentPopupForm = ({ onClose, data, open, refreshTable }) => {
  const { t } = useTranslate();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });

  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();
  const { control, handleSubmit, reset } = useForm();

  const onEntering = () => {
    if (data) {
      reset({
        name: data.name,
      });
    }
  };

  const onSubmit = async (formData) => {
    try {
      openSpinner();
      const payload = { name: formData.name };
      let response = null;
      if (!data._id) {
        response = await DepartmentAPI.create(payload);
      } else {
        response = await DepartmentAPI.update(data._id, payload);
      }
      refreshTable && refreshTable();
      showSuccessResponseMessage(response);
      onClose();
    } catch (error) {
      showErrorResponseMessage(error);
      console.log(error);
    } finally {
      closeSpinner();
    }
  };

  const content = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <InputLabel label={t('name')}>
          <ControlledTextField
            control={control}
            name={'name'}
            autoFocus
            placeholder={t('name')}
          />
        </InputLabel>
      </Grid>
      <Spinner state={spinnerState} />
    </Grid>
  );

  const action = (
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <Button
          size='small'
          variant='outlined'
          color='primary'
          onClick={onClose}
          sx={{ marginRight: '0.5rem' }}
        >
          {t('cancel')}
        </Button>
        <Button
          size='small'
          variant='contained'
          color='primary'
          onClick={handleSubmit(onSubmit)}
        >
          {t('confirm')}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <CustomDialog
      onExited={() => {
        reset({});
      }}
      onEntering={onEntering}
      title={t([data ? 'edit-' : 'add-', 'department'])}
      open={open}
      handleClose={onClose}
      content={content}
      action={action}
    />
  );
};
export default DepartmentPopupForm;
