import { Typography } from '@mui/material';

const titleVariant = {
  titlebar: {
    variant: 'h5',
    sx: { fontWeight: 'bold' },
  },
  title4: {
    sx: {
      textTransform: 'uppercase',
      fontSize: '32px',
      fontWeight: 500,
    },
  },
  title5: {
    color: 'primary',
    sx: {
      textTransform: 'uppercase',
      fontSize: '24px',
      fontWeight: 500,
    },
  },
  title6: {
    sx: {
      fontSize: '18px',
      fontWeight: 500,
    },
  },
  title7: {
    sx: {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  text2: {
    variant: 'body2',
  },
  text3: {
    variant: 'caption',
  },
};

const Text = ({
  variant,
  isNumber,
  format,
  sx,
  maxLine = 0,
  children,
  bold,
  prefix,
  suffix,
  fixedDecimalScale,
  ...props
}) => {
  const titleProps = titleVariant[variant];
  return (
    <Typography
      sx={{
        ...(maxLine > 0
          ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'contents',
              WebkitLineClamp: `${maxLine}`,
              WebkitBoxOrient: 'vertical',
            }
          : {}),
        ...(bold ? { fontWeight: 'bold' } : {}),
        ...sx,
      }}
      {...titleProps}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Text;
