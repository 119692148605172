import { CssBaseline } from '@mui/material';

import { useRoutes } from 'react-router-dom';
import ROUTES_PATH from 'src/routes';

function App() {
  const ROUTES = useRoutes(ROUTES_PATH);
  return (
    <>
      <CssBaseline />
      {ROUTES}
    </>
  );
}

export default App;
