import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GoogleMapPicker from '../google-map/google-map-picker';

import TitleContent from '../title-content';

const Map = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3} display='flex' justifyContent='center'>
      <Grid item xs={12}>
        <TitleContent title='title-map' />
      </Grid>
      <Grid item md={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign='center'>
            <Typography variant='subTitle1'>
              {t('form-map-description')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='location.coordinate'
              render={({ field: { value, onChange } }) => (
                <GoogleMapPicker
                  value={value}
                  onChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Map;
