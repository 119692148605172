import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
// Icon
import AddIcon from '@mui/icons-material/Add';

const TitleBar = ({
  title,
  btnTitle,
  onBtnClick,
  Icon = false,
  disableButton,
  marginTop = '45px',
  ...props
}) => {
  return (
    <Grid
      container
      sx={{ marginBottom: '2rem', marginTop: marginTop }}
      alignItems='center'
    >
      <Grid item xs>
        <Typography variant='h4' sx={{ fontWeight: 'bold' }} {...props}>
          {title}
        </Typography>
      </Grid>
      {!disableButton && (
        <Grid item xs='auto'>
          <Button
            size='small'
            variant='contained'
            color='primary'
            onClick={onBtnClick}
            startIcon={Icon ? <Icon /> : <AddIcon />}
          >
            {btnTitle}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TitleBar;
