const globalStyle = (theme) => ({
  /* change style scroll bar - Dont work on firefox */
  /* width */
  '*::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '*': {
    scrollbarWidth: 'thin',
  },
  /* Track */
  '* ::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  /* Handle */
  '* ::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '10px',
  },
});

export default globalStyle;
