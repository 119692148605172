import { React, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import JobRequirement from 'src/components/job/job-requirement';
import Spinner, { useSpinner } from 'src/components/spinner';
import useMessage from 'src/hooks/useMessage';
import JobAPI from 'src/api/job';
import ImageUploader from 'src/utils/ImageUploader';
import { useAuth } from 'src/context/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATH } from 'src/routes';
import GroupButton from '../button/GroupButton';
import JobTitle from './Job-title';
import JobStack from './job-stack';
import JobExpat from './job-expat';

const convertYesNoOptions = (value) => value === 'yes';
const convertTrueFalseOptions = (value) => {
  if (value === true) {
    return 'yes';
  } else {
    return 'no';
  }
};

const FormStepperJob = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();
  const methods = useForm();
  const [activeStep, setActiveStep] = useState(0);
  const isAvailableForExpats = methods.watch('isExpat');
  const { s3BaseURL } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    getJobById();
  }, [id]);

  const getJobById = async () => {
    try {
      openSpinner();
      if (id) {
        const { data } = await JobAPI.getById(id);

        const newDataInfo = {
          ...data.doc,
          isHandleWorkPermit: convertTrueFalseOptions(
            data.doc.isHandleWorkPermit,
          ),
          isHandleRelocationCost: convertTrueFalseOptions(
            data.doc.isHandleRelocationCost,
          ),
        };

        //reset Data to all fields in components
        methods.reset(newDataInfo);
      } else {
        const newDataInfo = { title: '' };
        methods.reset(newDataInfo);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeSpinner();
    }
  };

  const steps = useMemo(() => {
    return [
      <JobTitle />,
      <JobStack />,
      ...(isAvailableForExpats ? [<JobExpat />] : []),
      <JobRequirement />,
    ];
  }, [isAvailableForExpats]);

  const maxSteps = steps.length;

  const onSubmit = async (data) => {
    try {
      openSpinner();
      let requirement = data?.requirement.content;
      let benefit = data?.benefit.content;

      const imageUploader = new ImageUploader();
      const requirementImages = data?.requirement?.images;
      const benefitImages = data?.benefit?.images;

      requirementImages?.remove?.length > 0 &&
        imageUploader.removeImage(requirementImages?.remove);

      benefitImages?.remove?.length > 0 &&
        imageUploader.removeImage(benefitImages?.remove);

      if (
        requirementImages?.upload?.length > 0 ||
        benefitImages?.upload?.length > 0
      ) {
        const uploadOption = {
          basePath: `public/workspace-images`,
          baseURL: s3BaseURL,
          thumbnail: false,
        };
        await Promise.all([
          ...(requirementImages.upload.map(async (image) => {
            const s3Image = await imageUploader.addImage(
              image.file,
              uploadOption,
            );
            requirement = requirement.replace(image.blob, s3Image.key);
          }) || []),
          ...(benefitImages.upload.map(async (image) => {
            const s3Image = await imageUploader.addImage(
              image.file,
              uploadOption,
            );
            benefit = benefit.replace(image.blob, s3Image.key);
          }) || []),
        ]);
      }

      const newData = {
        ...data,
        isHandleWorkPermit: convertYesNoOptions(data.isHandleWorkPermit),
        isHandleRelocationCost: convertYesNoOptions(
          data.isHandleRelocationCost,
        ),
        requirement,
        benefit,
      };
      const [response] = await Promise.all([
        id ? await JobAPI.update(id, newData) : await JobAPI.create(newData),
        await imageUploader.exec(),
      ]);
      showSuccessResponseMessage(response);
      navigate(ROUTE_PATH.JOB);
    } catch (error) {
      console.log(error);
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const handleNext = async () => {
    const validated = await methods.trigger();
    if (validated === true) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <FormProvider {...methods}>
      {steps[activeStep]}
      <GroupButton
        handleBack={handleBack}
        handleNext={handleNext}
        onSubmit={methods.handleSubmit(onSubmit)}
        disableButtonSkip={true}
        disableButtonSave={activeStep >= 2 || !id ? true : false}
        disableButtonBack={activeStep === 0 && true}
        disableButtonNextAndSubmit={activeStep === maxSteps - 1 ? false : true}
      />
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default FormStepperJob;
