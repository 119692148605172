import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

const TitleContent = ({ title, variant = 'title' }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} textAlign='center'>
        <Typography variant={variant} color='primary'>
          {t(title)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleContent;
