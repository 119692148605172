import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import ControlledTextField from 'src/components/controlled-input/TextField';
import ControlledDateInput from '../controlled-input/DateInput';
import TitleContent from '../title-content';
import ControlledNumberInput from '../controlled-input/NumberInput';
import { useWatch } from 'react-hook-form';

import ControlledAutocomplete from 'src/components/controlled-input/AutoComplete';
import { useAuth } from 'src/context/AuthProvider';
import CompanyAPI from 'src/api/company';
import { useState } from 'react';
import { useEffect } from 'react';

const jobTypes = [
  'Programming',
  'Web Developer',
  'Software Engineering',
  'Network',
  'U/X UI',
  'Design',
  'Content writer',
];

const options = [
  'Contract',
  'Full Time',
  'Part Time',
  'Temporary',
  'Internship',
];

const JobTitle = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const minSalary = useWatch({
    name: 'minSalary',
  });
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    if (user.isSuperUser) {
      getAllCompany();
    }
  }, []);

  const getAllCompany = async () => {
    const { data } = await CompanyAPI.get();
    setCompanies(data.doc);
  };

  return (
    <Grid container spacing={3} display='flex' justifyContent='center'>
      <Grid item xs={12} textAlign='center'>
        <TitleContent title='what-is-the-job?' />
      </Grid>
      <Grid
        item
        lg={6}
        md={8}
        xs={12}
        sx={{
          '& input': {
            fontFamily: 'Poppins',
          },
          '& label': {
            fontFamily: 'Poppins',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name='title'
              placeholder={t('job-title')}
              sx={{
                '& input': {
                  textAlign: 'center ',
                  fontSize: '30px',
                },
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              size='medium'
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <ControlledNumberInput
              name='minSalary'
              label={t('minimum-salary')}
              placeholder={t('minimum-salary')}
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <ControlledNumberInput
              name='maxSalary'
              label={t('max-salary')}
              placeholder={t('max-salary')}
              rules={{
                validate: {
                  checkValue: (v) => v > minSalary,
                },
              }}
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name='type'
              label={t('job-type')}
              getOptionLabel={(option) => t(option)}
              placeholder={t(
                'job-type-(programming,-network,-data-science-etc.)',
              )}
              options={jobTypes}
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledDateInput
              name='deadline'
              label={t('deadline-date')}
              placeholder={t('deadline-date')}
              disablePast
              backgroundColor='white'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name='employmentType'
              getOptionLabel={(option) => t(option)}
              label={t('employment-type')}
              placeholder={t('employment-type')}
              options={options}
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
            />
          </Grid>
          {user.isSuperUser && (
            <Grid item xs={12}>
              <ControlledAutocomplete
                name='workspace'
                getOptionLabel={(option) => t(option?.name)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                label={t('choose-company')}
                placeholder={t('choose-company')}
                options={companies}
                sx={{
                  '.MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
                required
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobTitle;
