import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  IconButton,
  ImageList,
  ImageListItem,
} from '@mui/material';
// import { ImDownload } from 'react-icons/im';
import CancelIcon from '@mui/icons-material/Cancel';

import { ButtonContained } from 'src/components/button/ButtonContained';
import CandidateAPI from 'src/api/candidate';
import { useSearchParams } from 'react-router-dom';

const CustomMultipleImagesInput = ({
  onChange,
  defaultPreviews = null,
  innerText = "Drag 'n' Drop files here, or Click to select files",
  containerProps = {},
  dropZoneProps = {},
  disableButton,
  maxFiles = 5,
  maxFileSize = 5242880, // 5MB
}) => {
  const theme = useTheme();
  const [getParams] = useSearchParams();
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    if (getParams.get('id')) {
      getCVs();
    }
  }, []);

  const getCVs = async () => {
    const { data } = await CandidateAPI.getById(getParams.get('id'));
    setPreviews((prev) => {
      return [...prev, ...(data?.cv || [])];
    });
  };
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setPreviews((prev) => {
        return [...prev, ...acceptedFiles];
      });

      onChange((prev) => {
        return {
          files: [...prev.files, ...acceptedFiles],
          remove: [...prev.remove, ...acceptedFiles],
        };
      });
    },
    [onChange],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive = true,
    open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'text/html': ['.html', '.htm', '.pdf'],
    },
    maxFiles: maxFiles,
    maxSize: maxFileSize,
  });

  useEffect(() => {
    setPreviews(defaultPreviews);
  }, [defaultPreviews]);

  const handleRemoveImage = (index) => {
    setPreviews((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });

    onChange((prev) => {
      console.log('prev', prev);
      const temp = [...prev.files];
      temp.splice(index, 1);
      return { files: temp, remove: [...prev.remove, prev.files[index]] };
    });
  };

  return (
    <Box {...containerProps}>
      <Grid
        {...getRootProps()}
        {...dropZoneProps}
        sx={{
          ...dropZoneProps.sx,
          border: `2px dashed ${theme.palette.primary.main}`,
          cursor: 'pointer',
          minHeight: '280px',
          // borderRadius: '50px',
        }}
        container
        rowSpacing={1}
        alignContent='center'
        alignItems='center'
        mb={3}
      >
        <input {...getInputProps()} />
        <Grid item xs={12}>
          <Typography align='center'>{innerText}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: (theme) =>
                isDragActive ? theme.palette.primary.main : '#bdbdbd',
            }}
            align='center'
          >
            Or
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>Click</Typography>
        </Grid>
        {!disableButton && (
          <Grid item xs={12}>
            <ButtonContained
              size='medium'
              onClick={(e) => {
                e.stopPropagation();
                open();
              }}
            >
              <Typography>Upload</Typography>
            </ButtonContained>
          </Grid>
        )}
      </Grid>
      {previews && (
        <ImageList cols={4} rowHeight={30}>
          {previews.map((preview, index) => (
            <ImageListItem
              key={index}
              sx={{
                position: 'relative',
                lineHeight: 'normal',
                gridColumnEnd: 'span 4!important',
              }}
            >
              <IconButton
                color='error'
                size='small'
                sx={{ position: 'absolute', right: 0 }}
                onClick={() => handleRemoveImage(index)}
              >
                <CancelIcon fontSize='small' />
              </IconButton>
              <Typography>{preview?.name}</Typography>
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
};

export default CustomMultipleImagesInput;
