import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useController } from 'react-hook-form';

const ControlSwitch = (props) => {
  const {
    name,
    control,
    required,
    rules,
    label,
    defaultValue,
    size,
    color = 'primary',
    ...switchProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    defaultValue,
    control, //optional when use inside formContext
    rules: { required, ...rules },
  });

  return (
    <Switch
      onClick={(e) => {
        e.stopPropagation();
      }}
      checked={value}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      size={size}
      color={color}
      {...switchProps}
    />
  );
};

export default ControlSwitch;
