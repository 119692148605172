import { nanoid } from 'nanoid';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import awsS3API from 'src/api/s3';

/**
 * Generate Random Key
 * @returns {string} generated random key
 */
const generateKey = () => {
  return `${nanoid(5)}_${new Date().getTime()}`;
};

const option = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1200,
  useWebWorker: true,
};

const thumbnailOption = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 500,
  useWebWorker: true,
};

const compressImage = async (file, thumbnail = true, updateOption = {}) => {
  const image = await imageCompression(file, { ...option, ...updateOption });
  if (!thumbnail) {
    return image;
  } else {
    const thumbnail = await imageCompression(file, {
      ...thumbnailOption,
      ...updateOption,
    });
    return { image, thumbnail };
  }
};
export { thumbnailOption, option };

export const uploadImage = async (
  uploadFile,
  { basePath, baseURL, isPublic = true },
) => {
  const uploadObject = {
    ContentTypes: [],
    Keys: [],
    Images: [],
  };

  const fileType = uploadFile.type;
  const fileExtension = uploadFile.type.split('/')[1];

  // generate key
  uploadObject.ContentTypes.push(fileType, fileType);
  const key = `${basePath}/${generateKey()}`;
  const imageKey = `${key}.${fileExtension}`;
  const thumbnailKey = `${key}-thumbnail.${fileExtension}`;

  uploadObject.Keys.push(imageKey, thumbnailKey);

  // compress image
  const { image, thumbnail } = await compressImage(uploadFile);
  uploadObject.Images.push(image, thumbnail);

  const uploadedImage = {
    mimeType: fileType,
    key: `${isPublic ? `${baseURL}/` : ''}${imageKey}`,
    thumbnail: `${isPublic ? `${baseURL}/` : ''}${thumbnailKey}`,
  };

  // get s3 upload url
  const response = await awsS3API.getFileUploadURL({
    ContentTypes: uploadObject.ContentTypes,
    Keys: uploadObject.Keys,
    isPublic,
  });

  await Promise.all(
    response.data.map(async (item, index) => {
      await axios.put(item.uploadURL, uploadObject.Images[index], {
        headers: {
          'Content-Type': item.type,
          'Content-Encoding': 'base64',
        },
        withCredentials: false,
      });
    }),
  );

  return uploadedImage;
};

export default uploadImage;
