import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Avatar, Grid, ListItemIcon, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Text from '../typography/Text';
import { useAuth } from 'src/context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'src/routes';
import { Person } from '@mui/icons-material';

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  return {
    children: `${
      name?.split(' ').length > 1
        ? name?.split(' ')[0][0] + name?.split(' ')[1][0] || 'N/A'
        : name?.split(' ')[0][0] + name?.split(' ')[0][1] || 'N/A'
    }`,
  };
};

const ButtonAccount = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!user) {
      navigate(ROUTE_PATH.REGISTER);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='account'
        size='small'
        color='primary'
        sx={{
          pr: 1,

          '&:hover': {
            background: (theme) => theme.palette.grey[200],
          },
          textTransform: 'none',
          color: (theme) => theme.palette.primary.main,
        }}
        onClick={handleClick}
      >
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <Avatar
              src={user?.profile?.thumbnail}
              sx={{
                border: (theme) => `1px solid ${theme.palette.grey[200]}`,
                width: 50,
                height: 50,
                backgroundColor: stringToColor(
                  user?.fullname ? user?.fullname : user?.email,
                ),
              }}
              {...stringAvatar(user?.fullname ? user?.fullname : user?.email)}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ color: 'black', textAlign: 'left' }}>
              Hello,
            </Typography>
            <Grid container alignItems='center'>
              <Grid item>
                <Text variant='text2' maxLine={2} noWrap>
                  {user ? user?.fullname : 'Sign In'}
                </Text>
              </Grid>
              {!!user && <Grid item></Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Button>
      <Menu
        id='account-menu'
        sx={{ marginTop: 1 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            navigate(ROUTE_PATH.PROFILE);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          {t('profile')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();

            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ButtonAccount;
