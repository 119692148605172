import axios from 'axios';

const DepartmentAPI = {
  get: (params) => {
    return axios.get('department', { params });
  },
  create: (payload) => {
    return axios.post('department', payload);
  },
  update: (id, payload) => {
    return axios.put(`department/${id}`, payload);
  },
  delete: (id) => {
    return axios.delete(`department/${id}`);
  },
  getURL: 'department',
};

export default DepartmentAPI;
