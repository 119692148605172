/* eslint-disable no-undef */
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { nanoid } from 'nanoid';
import S3 from '../api/s3';

const option = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1200,
  useWebWorker: true,
};

const thumbnailOption = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 500,
  useWebWorker: true,
};

class ImageUploader {
  constructor() {
    this.images = [];
    this.removeImages = [];
  }

  generateKey() {
    return `${nanoid(5)}_${new Date().getTime()}`;
  }

  async compressImage(file, thumbnail = true, updateOption = {}) {
    const image = await imageCompression(file, { ...option, ...updateOption });
    if (!thumbnail) {
      return image;
    } else {
      const thumbnail = await imageCompression(file, {
        ...thumbnailOption,
        ...updateOption,
      });
      return { image, thumbnail };
    }
  }

  async addImage(inputImageFile, { basePath, baseURL, thumbnail = true } = {}) {
    const imageFiles = [];
    if (Array.isArray(inputImageFile)) {
      imageFiles.push(...inputImageFile);
    } else if (inputImageFile) {
      imageFiles.push(inputImageFile);
    }

    if (imageFiles.length === 0) {
      return [];
    }

    const result = await Promise.all(
      imageFiles.map(async (imageFile) => {
        if (!this.isValid(imageFile)) {
          return imageFile;
        }

        const fileType = imageFile.type.split('/')[0];
        const fileExtension = imageFile.type.split('/')[1];

        // generate key
        const key = `${basePath || this.defaultBasePath}/${this.generateKey()}`;
        const imageKey = `${key}.${fileExtension}`;
        const thumbnailKey = `${key}-thumbnail.${fileExtension}`;

        if (fileType === 'image') {
          // compress image
          const { image, thumbnail: compressThumbnail } =
            await this.compressImage(imageFile);

          const uploadedImage = {
            mimeType: fileType,
            key: `${baseURL ? `${baseURL}/` : ''}${imageKey}`,
          };

          if (thumbnail) {
            uploadedImage.thumbnail = `${
              baseURL ? `${baseURL}/` : ''
            }${thumbnailKey}`;
          }

          this.images.push({
            mimeType: fileType,
            key: imageKey,
            file: image,
            ...(thumbnail
              ? { thumbnailFile: compressThumbnail, thumbnail: thumbnailKey }
              : {}),
          });
          return { ...uploadedImage, name: imageFile?.name };
        } else {
          const uploadedImage = {
            mimeType: fileType,
            key: `${baseURL ? `${baseURL}/` : ''}${imageKey}`,
          };

          if (thumbnail) {
            uploadedImage.thumbnail = `${
              baseURL ? `${baseURL}/` : ''
            }${thumbnailKey}`;
          }

          this.images.push({
            mimeType: fileType,
            key: imageKey,
            file: imageFile,
            ...(thumbnail
              ? { thumbnailFile: compressThumbnail, thumbnail: thumbnailKey }
              : {}),
          });
          return { ...uploadedImage, name: imageFile?.name };
        }
      }),
    );

    if (Array.isArray(inputImageFile)) {
      return result;
    } else {
      return result[0];
    }
  }

  removeImage(image) {
    if (image) {
      if (Array.isArray(image)) {
        this.removeImages.push(...image);
      } else {
        this.removeImages.push(image);
      }
    }
  }

  isValid(inputImageFile) {
    return inputImageFile instanceof File || inputImageFile instanceof Blob;
  }

  async exec() {
    const uploadObject = {
      ContentTypes: [],
      Keys: [],
      Images: [],
    };

    this.images.forEach((image) => {
      uploadObject.ContentTypes.push(image.mimeType);
      uploadObject.Keys.push(image.key);
      uploadObject.Images.push(image.file);
      if (image.thumbnail) {
        uploadObject.ContentTypes.push(image.mimeType);
        uploadObject.Keys.push(image.thumbnail);
        uploadObject.Images.push(image.thumbnailFile);
      }
    });

    let response = null;
    if (this.images.length > 0) {
      // get s3 upload url
      response = await S3.getFileUploadURL({
        ContentTypes: uploadObject.ContentTypes,
        Keys: uploadObject.Keys,
        isPublic: false,
      });
    } else {
      response = { data: [] };
    }

    await Promise.all([
      ...(this.images.length > 0
        ? response?.data?.map(async (item, index) => {
            await axios.put(item.uploadURL, uploadObject.Images[index], {
              headers: {
                'Content-Type': item.type,
                'Content-Encoding': 'base64',
              },
              withCredentials: false,
            });
          })
        : []),
      this.removeImages.length > 0 &&
        S3.removeFiles(
          this.removeImages.reduce((acc, curr) => {
            acc.push({ Key: curr?.key });
            if (curr?.thumbnail) {
              acc.push({ Key: curr?.thumbnail });
            }
            return acc;
          }, []),
          false,
        ),
    ]);
  }
}

export default ImageUploader;
