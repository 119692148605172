import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Slide,
  Stack,
  DialogActions,
} from '@mui/material';
// Icon
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const CustomDialog = ({
  open,
  align = 'right',
  handleClose,
  title,
  content,
  action,
  fixedContent = false,
  onEnter,
  onExit,
  onExited,
  onEntering,
  ...otherProps
}) => {
  return (
    <Dialog
      fullWidth
      TransitionProps={{ onEnter, onExit, onEntering, onExited }}
      TransitionComponent={Transition}
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
          alignItems: 'flex-start',
        },
        '& .MuiDialog-paper': {
          margin: 0,
          maxHeight: '100%',
          height: '100%',
        },
      }}
      {...otherProps}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <IconButton onClick={handleClose} color='primary'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ height: '100%' }}>
          {content}
          {!fixedContent ? action : null}
        </Stack>
      </DialogContent>
      {fixedContent ? <DialogActions>{action}</DialogActions> : null}
    </Dialog>
  );
};

export default CustomDialog;
