import React from 'react';
import { Box } from '@mui/material';
import FormStepper from 'src/components/job';
import TitleBar from 'src/components/title-bar';

const CreateJob = () => {
  return (
    <Box>
      <TitleBar disableButton={true} />
      <FormStepper />
    </Box>
  );
};

export default CreateJob;
