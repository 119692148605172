import { TextField } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers';
// required to be inside MuiPickersUtilsProvider
const ControlledDateInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = null,
    variant = 'inline',
    inputVariant = 'outlined',
    size = 'small',
    inputFormat,
    type = 'date',
    onChange,
    customComponent,
    rules,
    backgroundColor,
    ...datePickerProps
  } = props;

  const Component =
    customComponent ?? type === 'time' ? DesktopTimePicker : DesktopDatePicker;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: fieldOnChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: {
      required,
      ...rules,
    },
    defaultValue,
  });

  return (
    <Component
      inputVariant={inputVariant}
      autoFocus={true}
      autoOk
      inputRef={ref}
      variant={variant}
      size={size}
      inputFormat={inputFormat ?? type === 'time' ? 'HH:mm a' : 'DD/MM/YYYY'}
      margin='normal'
      value={value}
      onChange={
        onChange ? (date) => onChange(date, inputProps.onChange) : fieldOnChange
      }
      fullWidth
      {...datePickerProps}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!error}
            sx={{
              '.MuiOutlinedInput-root': {
                backgroundColor,
              },
              '& svg': { fill: (theme) => theme.palette.primary.main },
            }}
          />
        );
      }}
    />
  );
};

export default ControlledDateInput;
