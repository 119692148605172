import { AppBar as MuiAppBar, Toolbar, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import Profile from './profile';
import ButtonAccount from 'src/components/button/ButtonAccount';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // width: `calc(100% - ${65}px)`,
  width: 'auto',
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open, toggle }) => {
  return (
    <AppBar
      elevation={1}
      position='absolute'
      open={open}
      sx={{
        top: 0,
        m: 3,
        left: 0,
        height: '70px',
        marginTop: '20px',
        borderRadius: '6px',
        background: (theme) => theme.palette.navbar.background,
      }}
    >
      <Toolbar
        sx={{
          padding: {
            xs: 0,
          },
          height: 'px',
        }}
      >
        <IconButton
          sx={{ color: 'white', marginLeft: '0.75rem' }}
          onClick={() => {
            toggle();
          }}
        ></IconButton>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mr: 2,
            pt: '5px',
          }}
        >
          <ButtonAccount />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
