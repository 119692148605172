import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CandidateAPI from 'src/api/candidate';

import ControlledAutocompleteChip from '../controlled-input/AutoCompleteChip';

import ControlledPhoneNumberInput from '../controlled-input/PhoneInput';
import ControlSwitch from '../controlled-input/Switch';
import ControlledTextField from '../controlled-input/TextField';
import Spinner, { useSpinner } from '../spinner';
import TitleBar from '../title-bar';
import ControlledNationalities from '../controlled-input/nationalities';
import ControlledCKEditor from '../controlled-input/ckeditor';
import UploadCVForm from './upload-cv/upload-cv-form';

const values = ['Developer', 'Programmer'];

const CreateCandidate = () => {
  const { t } = useTranslation();
  const methods = useForm();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });

  const [getParams] = useSearchParams();
  const [cv, setCV] = useState([]);

  useEffect(() => {
    getCandidateById();
  }, [getParams]);
  const getCandidateById = async () => {
    try {
      openSpinner();
      if (getParams.get('id')) {
        const { data } = await CandidateAPI.getById(getParams.get('id'));
        setCV(data.data.cv);
        const newData = {
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          email: data.data.email,
          phone: data.data.phone,
          skills: data.data.skills,
          nationality: data.data.nationality,
          summary: data.data.summary,
        };
        //reset Data to all fields in components
        methods.reset(newData);
      } else {
        const newData = {
          phone: '+855',
        };
        methods.reset(newData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeSpinner();
    }
  };

  return (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar
            title={getParams.get('name') ?? t('new-candidate')}
            disableButton={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems='center'>
            <Grid item xs={6}>
              <Typography>{t('create-new-candidate-description')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                display='flex'
                justifyContent='end'
                alignItems='center'
              >
                <Grid item>
                  <Typography>{t('available-for-a-new-job')}</Typography>
                </Grid>
                <Grid item>
                  <ControlSwitch name='availableForJob' defaultValue={false} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Grid container columnSpacing={10} rowSpacing={4}>
            <Grid item xs={6}>
              <ControlledTextField
                name='firstName'
                label={t('first-name')}
                size='small'
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name='lastName'
                label={t('last-name')}
                size='small'
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name='email'
                label={t('email')}
                size='small'
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledPhoneNumberInput
                name='phone'
                label={t('phone-number')}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledAutocompleteChip
                name='skills'
                options={values}
                label={t('skill')}
                freeSolo
                size='small'
                sx={{
                  '.MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    height: '',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledNationalities
                name='nationality'
                label={t('nationality')}
                size='small'
              />
            </Grid>
            <Grid item xs={12}>
              {!spinnerState.state ? (
                <Grid item xs={12}>
                  <UploadCVForm data={cv} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default CreateCandidate;
