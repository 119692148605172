import StorageIcon from '@mui/icons-material/Storage';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ReorderIcon from '@mui/icons-material/Reorder';
import AddIcon from '@mui/icons-material/Add';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useAuth } from 'src/context/AuthProvider';

/* follow react router routes style (Required AccessControl Component)
 menuConfig consist of 2 element 
  - basePath <String> : root path of every path - ex: dashboard
  - menu <Array Object> : list of path 
        - label <String> : Name of the label
        - path <String> : path url ( will take basePath combine with current path)
        - index <Boolean> : if it is root of the path. if your basePath is dashboard and you want to use /dashboard as your path (index:true)
        - exact <Boolean> : active only if the current url match exact path
        - icon <React Component> : icon to display
        - access Array[execute,feature] : hide or show tab base on role check with accessControl 
        - children <Array Object> : if menu have submenu have access to all object above except icon and children
*/
// menu Config translation use namespace so it wont affect with other (menu.json)

const menuConfig = {
  basePath: 'dashboard',
  menu: [
    {
      icon: DashboardIcon,
      label: 'dashboard',
      index: true,
      exact: true,
      // access: ['view', 'job'],
    },
    {
      label: 'candidate',
      path: 'candidate',
      icon: GroupIcon,
      access: ['view', 'candidate'],
      children: [
        {
          icon: ReorderIcon,
          label: 'candidate-list',
          index: true,
          access: ['view', 'candidate'],
          exact: true,
        },
        {
          icon: AddIcon,
          label: 'create-candidate',
          path: 'create',
          access: ['create', 'candidate'],
          exact: true,
        },
      ],
    },
    {
      label: 'job',
      path: 'job',
      icon: WorkIcon,
      access: ['view', 'job'],
      children: [
        {
          icon: ReorderIcon,
          label: 'job-list',
          index: true,
          access: ['view', 'job'],
          exact: true,
        },
        {
          icon: AddIcon,
          label: 'create-new-job',
          path: 'create',
          access: ['create', 'job'],
          exact: true,
        },
      ],
    },
    {
      label: 'company',
      path: 'company',
      icon: AddBusinessIcon,
      access: ['view', 'company'],
      children: [
        {
          icon: ReorderIcon,
          label: 'company-list',
          index: true,
          access: ['view', 'company'],
          exact: true,
        },
        // {
        //   icon: AddIcon,
        //   label: 'create-new-job',
        //   path: 'create',
        //   access: ['create', 'company'],
        //   exact: true,
        // },
      ],
    },
    {
      label: 'setting',
      icon: SettingsApplicationsIcon,
      path: 'setting',
      children: [
        {
          label: 'user',
          icon: ManageAccountsIcon,
          path: 'user',
          access: ['view', 'user'],
          exact: true,
        },
        {
          label: 'role-permission',
          icon: VerifiedUserIcon,
          path: 'role-permission',
          access: ['view', 'role-permission'],
          exact: true,
        },
      ],
    },
  ],
};

export default menuConfig;
