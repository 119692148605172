import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormBenefits from './form-benefits';
import FormCompanyInformation from './form-info';
import FormLogo from './form-logo';
import { useTranslation } from 'react-i18next';
import Map from './map';
import { useState } from 'react';
import GroupButton from '../button/GroupButton';
import companyAPI from 'src/api/company';
import handleProfileImage from 'src/utils/upload-profile-image';
import Spinner, { useSpinner } from '../spinner';
import useMessage from 'src/hooks/useMessage';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'src/routes';
import { useAuth } from 'src/context/AuthProvider';

const steps = [
  {
    component: <FormCompanyInformation />,
    canSkip: false,
  },
  {
    component: <Map />,
    canSkip: true,
  },
  {
    component: <FormBenefits />,
    canSkip: false,
  },
  {
    component: <FormLogo />,
    canSkip: false,
  },
];

const FormCompanyInfo = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm();
  const { getMe } = useAuth();
  const navigate = useNavigate();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();

  const maxSteps = steps.length;
  const handleNext = async () => {
    const validated = await methods.trigger();
    if (validated === true) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    methods.setValue('location.coordinate.lat', '');
    methods.setValue('location.coordinate.lng', '');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onSubmit = async (data) => {
    openSpinner();
    try {
      let image = {};
      const location = data?.coordinate;
      const city = data?.city.name;
      let benefitAndCulture = data?.benefitAndCulture?.content;

      const uploadImage = await handleProfileImage(data.image, {
        basePath: 'public/workspace-images',
        isPublic: true,
      });

      if (uploadImage) {
        image = uploadImage;
      }

      const newData = {
        ...data,
        image,
        location,
        city,
        benefitAndCulture,
      };

      const [response] = await Promise.all([await companyAPI.update(newData)]);
      await getMe();
      showSuccessResponseMessage(response);
      navigate(ROUTE_PATH.DASHBOARD);
    } catch (error) {
      console.log('error create company', error);
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };
  return (
    <FormProvider {...methods}>
      {steps[activeStep].component}

      <GroupButton
        enableButtonSkip={steps[activeStep].canSkip}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSkip={handleSkip}
        onSubmit={methods.handleSubmit(onSubmit)}
        disableButtonBack={activeStep === 0 && true}
        disableButtonNextAndSubmit={activeStep === maxSteps - 1 ? false : true}
      />
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default FormCompanyInfo;
