import { Avatar, ButtonBase } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const style = {
  buttonBase: {
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    transition: '0.1s linear',
    ':hover': {
      backgroundColor: 'action.hover',
    },
  },
  avatar: (width, height) => ({
    width: width || '120px',
    height: height || '120px',
    border: (theme) => `1px solid ${theme.palette.grey[300]}`,
    padding: 0,
    '& svg': {
      fontSize: '3rem',
    },
  }),
};

const ClickableAvatar = ({ image, width, height, onClick, avatarIcon }) => {
  return (
    <ButtonBase onClick={onClick} sx={style.buttonBase}>
      <Box sx={style.overlay} />
      <Avatar
        src={image.file ? image.blob : image.src && image?.src?.thumbnail}
        sx={style.avatar(width, height)}
      >
        {avatarIcon}
      </Avatar>
    </ButtonBase>
  );
};

export default ClickableAvatar;
