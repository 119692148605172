import { ExpandMore, WorkspacePremiumOutlined } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useAccessControl } from 'src/components/access-control';
import menuConfig from './menu-config';
import buildPath from './utils/build-path';
import { useAuth } from 'src/context/AuthProvider';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

const CustomNavLink = ({ show = true, ...props }) => {
  return show ? <ListItemButton component={NavLink} {...props} /> : null;
};

const CustomNavLinkStyle = styled(CustomNavLink)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    '& svg': {
      // icon color
      color: theme.palette.drawer.iconColor,
    },
    // text color
    color: theme.palette.drawer.textColor,
    // drawer selected
    '&.active': {
      ...theme.palette.drawer.selected,
    },
    //drawer hover
    '&:hover:not(.active)': {
      ...theme.palette.drawer.hover,
    },
  },
  padding: '8px 19px',
}));

const CustomSubNavLinkStyle = styled(CustomNavLinkStyle)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    '&.active': {
      ...theme.palette.drawer.subSelected,
    },
  },
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '3rem',
}));

const basePath = menuConfig.basePath;
const menu = menuConfig.menu;

const Menu = ({ open }) => {
  const checkAccessControl = useAccessControl();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('menu'); //using namespace
  const { user } = useAuth();
  return (
    <List sx={{ padding: 0 }}>
      {menu.map((item, index) => {
        const Icon = item.icon;
        const path = buildPath(basePath, item);
        const isMatch = location.pathname.includes(path) && open;

        // permission check
        let show = true;
        if (item.access && user?.isSuperUser) {
          show = checkAccessControl(...item.access);
        }
        if (item.access && !user?.isSuperUser) {
          if (item.access[1] === 'candidate') return false;
          if (item.access[1] === 'company') return false;
          show = checkAccessControl(...item.access);
        }

        return (
          <React.Fragment key={index}>
            <CustomNavLinkStyle
              show={show}
              component={NavLink}
              end={item.exact}
              to={path}
              onClick={(e) => {
                e.preventDefault(); //to keep using parent active functionality
                navigate(
                  item.children
                    ? buildPath(
                        basePath,
                        item,
                        item.children?.find((el) =>
                          checkAccessControl(...el.access),
                        ),
                      )
                    : path,
                );
              }}
            >
              <CustomListItemIcon>{item.icon && <Icon />}</CustomListItemIcon>
              <ListItemText
                primary={t(item.label)}
                sx={{ fontFamily: 'Poppins !important' }}
              />
              {item.children && (
                <CustomListItemIcon>
                  <ExpandMore />
                </CustomListItemIcon>
              )}
            </CustomNavLinkStyle>
            {item.children && (
              <Collapse in={isMatch}>
                {item.children.map((subItem, index) => {
                  // permission check
                  const Icon = subItem.icon;
                  let subShow = true;
                  if (subItem.access) {
                    subShow = checkAccessControl(...subItem.access);
                  }
                  return (
                    <CustomSubNavLinkStyle
                      show={subShow}
                      key={index}
                      end={subItem?.exact}
                      to={buildPath(basePath, item, subItem)}
                    >
                      <CustomListItemIcon>
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                      </CustomListItemIcon>

                      <ListItemText primary={t(subItem?.label)} />
                    </CustomSubNavLinkStyle>
                  );
                })}
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default Menu;
