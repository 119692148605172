import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

const Prompt = ({ state, backdropClose = true }) => {
  return (
    <Dialog
      open={state.state}
      onClose={() => backdropClose && state.onClose()}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          marginBottom: '1rem',
          color: 'white',
          padding: '0.75rem 1rem',
        }}
      >
        {state.title}
      </DialogTitle>
      {state.message && <DialogContent>{state.message}</DialogContent>}
      <DialogActions>
        {state?.buttons?.map((button, index) => (
          <Button key={index} {...state?.buttonActions?.[index]} {...button}>
            {button.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export const usePrompt = (
  { title, message, buttons, buttonActions, middleware } = {
    title: '',
    message: '',
    buttons: [],
    buttonActions: [],
    middleware: null,
  },
) => {
  const [state, setState] = useState({
    state: false,
    title,
    message,
    buttons,
    buttonActions,
  });

  /**
   * Open Prompt with modifier
   * @param {object} modifier
   * @param {string} modifier.title Prompt Title
   * @param {string} modifier.message Prompt Message
   * @param {object[]} modifier.buttons Prompt Buttons
   * @param {object[]} modifier.buttonActions Prompt Buttons Props
   */
  const openPrompt = (modifier = {}) => {
    if (middleware) {
      modifyState(true, middleware(modifier));
    } else {
      modifyState(true, modifier);
    }
  };

  const closePrompt = (modifier = {}) => {
    modifyState(false, modifier);
  };

  const modifyState = (state, modifier) => {
    if (modifier.target) {
      setState((prev) => {
        return { ...prev, state };
      });
    } else {
      setState((prev) => {
        return { ...prev, state, ...modifier };
      });
    }
  };

  return {
    promptState: { ...state, onClose: closePrompt },
    openPrompt,
    closePrompt,
  };
};

export default Prompt;
