import { Grid, Typography } from '@mui/material';

import React, { useState } from 'react';
import Spinner, { useSpinner } from 'src/components/spinner';

import CustomMultipleImagesInput from 'src/components/controlled-input/drag-drop';
import { ButtonContained } from 'src/components/button/ButtonContained';
import ViewFileUpload from './views-file-uploaded';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ImageUploader from 'src/utils/ImageUploader';
import CandidateAPI from 'src/api/candidate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useMessage from 'src/hooks/useMessage';
import { ROUTE_PATH } from 'src/routes';
import ControlledCKEditor from 'src/components/controlled-input/ckeditor';
import TitleBar from 'src/components/title-bar';

const UploadCVForm = ({ data }) => {
  const { t } = useTranslation();
  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();
  const methods = useFormContext();

  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });

  const [getParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState({ files: [], remove: [] });
  const [defaultPreviews, setDefaultPreviews] = useState([]);

  const onSubmit = async (newDataBody) => {
    let cv = [];
    const handleUpload = new ImageUploader();
    const upload = uploadFile?.files;
    try {
      openSpinner();
      // check limit item of cv
      if (getParams.get('id')) {
        if (data?.cv?.length > 10) {
          throw new Error('CV Upload has Limit. 10 only!');
        }

        if (upload.length >= 0) {
          const uploadOption = {
            basePath: `private/workspace-images`,
            baseURL: '',
            thumbnail: false,
          };

          await Promise.all([
            ...(upload.map(async (file) => {
              const s3File = await handleUpload.addImage(file, uploadOption);
              cv.push(s3File);
            }) || []),
          ]);

          const newData = {
            ...newDataBody,
            cv,
            summary: newDataBody.summary.content,
          };

          const [response] = await Promise.all([
            await CandidateAPI.uploadCV(getParams.get('id'), newData),
            await CandidateAPI.update(getParams.get('id'), newDataBody),
            await handleUpload.exec(),
          ]);

          setDefaultPreviews([]);
          setUploadFile({ files: [], remove: [] });
          showSuccessResponseMessage(response);
          // navigate(`${ROUTE_PATH.CANDIDATE}`);
        }
      } else {
        if (data?.cv?.length > 10) {
          throw new Error('CV Upload has Limit. 10 only!');
        }

        if (
          uploadFile?.files?.length === 0 ||
          uploadFile?.remove?.length === 0
        ) {
          throw new Error('Please choose your file for upload!');
        }

        let cv = [];

        const handleUpload = new ImageUploader();

        const upload = uploadFile?.files;

        if (upload.length > 0) {
          const uploadOption = {
            basePath: `private/workspace-images`,
            baseURL: '',
            thumbnail: false,
          };

          await Promise.all([
            ...(upload.map(async (file) => {
              const s3File = await handleUpload.addImage(file, uploadOption);
              cv.push(s3File);
            }) || []),
          ]);

          const newData = {
            ...newDataBody,
            cv,
            summary: newDataBody.summary.content,
          };
          const [response] = await Promise.all([
            await CandidateAPI.create(newData),
            await handleUpload.exec(),
          ]);

          setDefaultPreviews([]);
          setUploadFile({ files: [], remove: [] });
          showSuccessResponseMessage(response);
          navigate(`${ROUTE_PATH.CANDIDATE}`);
        }
      }
    } catch (error) {
      cv.map((item) => {
        handleUpload.removeImage(item);
      });
      await Promise.all([handleUpload.exec()]);
      console.log(error);
      showErrorResponseMessage(error);
      setIsLoading(false);
    } finally {
      closeSpinner();
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='center'
        textAlign='center'
      >
        <Grid
          container
          display='flex'
          justifyContent='center'
          columnSpacing={10}
        >
          <Grid item xs={6}>
            <ControlledCKEditor name='summary' />
          </Grid>
          <Grid item xs={6}>
            <TitleBar
              disableButton={true}
              title={t('upload-cv')}
              marginTop={0}
            />
            <CustomMultipleImagesInput
              disableButton={true}
              onChange={setUploadFile}
              defaultPreviews={defaultPreviews}
              containerProps={{
                sx: {
                  marginBottom: '1rem',
                },
              }}
              dropZoneProps={{ sx: { height: '100px' } }}
              innerText={t('drag-&-drop-your-cv-here')}
            />
          </Grid>
          <Grid item xs={12} textAlign='end'>
            {getParams.get('id') ? (
              <ButtonContained onClick={methods.handleSubmit(onSubmit)}>
                <Typography>{t('update')}</Typography>
              </ButtonContained>
            ) : (
              <ButtonContained onClick={methods.handleSubmit(onSubmit)}>
                <Typography>{t('save')}</Typography>
              </ButtonContained>
            )}
          </Grid>
        </Grid>
      </Grid>
      {getParams.get('id') && (
        <Grid container item>
          <ViewFileUpload data={data} defaultPreviews={defaultPreviews} />
        </Grid>
      )}
      <Spinner state={spinnerState} />
    </Grid>
  );
};

export default UploadCVForm;
