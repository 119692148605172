import awsS3API from 'src/api/s3';
import uploadImage from './image-upload';

const handleProfilePictureEditorFile = ({
  handleRemovedImage,
  handleNewImage,
}) => {
  return async (profile) => {
    if (profile?.file) {
      if (profile.remove.length > 0) {
        await handleRemovedImage(profile.remove);
      }
      return await handleNewImage(profile.file);
    }
  };
};

const handleProfileImage = async (
  profile,
  { basePath, isPublic } = {
    basePath: 'private/user-profile',
    isPublic: false,
  },
) => {
  let baseURL = null;
  console.log('isPublic', isPublic);
  if (isPublic) {
    const res = await awsS3API.getBaseURL();
    baseURL = res.data.url;
  }

  const callback = handleProfilePictureEditorFile({
    handleRemovedImage: awsS3API.removeFiles,
    handleNewImage: (newFile) => {
      return uploadImage(newFile, {
        basePath,
        baseURL,
        isPublic,
      });
    },
  });

  return await callback(profile);
};

export default handleProfileImage;
