export const deletePrompt = ({ title, onConfirm, onCancel, message }) => ({
  title: title,
  message: message || 'are-you-sure',
  buttons: [
    {
      text: 'delete',
      onClick: (e) => {
        e.stopPropagation();
        onConfirm();
      },
      variant: 'outlined',
      color: 'error',
    },
    {
      text: 'cancel',
      onClick: (e) => {
        e.stopPropagation();
        onCancel();
      },

      variant: 'contained',
      color: 'primary',
    },
  ],
});
