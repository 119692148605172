import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './sample-redux/counterSlice';
import uiSlice from './ui-redux/uiSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    ui: uiSlice
  },
});
