import { Autocomplete, Chip, TextField } from '@mui/material';
import { forwardRef } from 'react';

const AutoCompleteChip = forwardRef(
  (
    {
      options,
      value,
      onChange,
      getOptionSelected,
      getOptionLabel,
      getChipLabel,
      placeholder,
      error,
      isOptionEqualToValue,
      label,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <Autocomplete
        multiple
        autoHighlight
        disableClearable
        value={value}
        options={options}
        filterSelectedOptions
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        onChange={(_, data) => {
          onChange(data);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            return (
              <Chip
                label={getChipLabel(option)}
                style={{ margin: '2px 2px' }}
                {...getTagProps({ index })}
                color='primary'
                size='small'
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            inputRef={ref}
            variant='outlined'
            InputProps={{ ...params.InputProps }}
            error={!!error}
            label={label}
            placeholder={placeholder}
          />
        )}
        {...inputProps}
      ></Autocomplete>
    );
  },
);
export default AutoCompleteChip;
