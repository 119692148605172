import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonContained } from './ButtonContained';

const GroupButton = ({
  disableButtonBack,
  disableButtonNextAndSubmit,
  disableButtonSave,
  enableButtonSkip,
  onSubmit,
  handleSkip,
  handleBack,
  handleNext,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container mt={3} mb={10}>
      <Grid item xs={12}>
        <Grid
          container
          display='flex'
          justifyContent='center'
          textAlign='center'
          columnSpacing={10}
        >
          {!disableButtonSave && (
            <Grid item>
              <ButtonContained size='large' onClick={onSubmit}>
                <Typography variant='h1'> {t('submit')}</Typography>
              </ButtonContained>
            </Grid>
          )}
          {!disableButtonBack && (
            <Grid item>
              <ButtonContained
                size='large'
                color='secondary'
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  px: '48px',
                }}
                onClick={handleBack}
              >
                <Typography variant='h1'>{t('back')}</Typography>
              </ButtonContained>
            </Grid>
          )}
          {enableButtonSkip && (
            <Grid item>
              <ButtonContained size='large' onClick={handleSkip}>
                <Typography variant='h1'>{t('skip')}</Typography>
              </ButtonContained>
            </Grid>
          )}
          {!disableButtonNextAndSubmit ? (
            <Grid item>
              <ButtonContained size='large' onClick={onSubmit}>
                <Typography variant='h1'> {t('submit')}</Typography>
              </ButtonContained>
            </Grid>
          ) : (
            <Grid item>
              <ButtonContained size='large' onClick={handleNext}>
                <Typography variant='h1'>{t('next')}</Typography>
              </ButtonContained>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupButton;
