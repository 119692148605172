import { Box, Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CandidateAPI from 'src/api/candidate';
import DepartmentPopupForm from 'src/components/department/department-popup-form';
import Prompt from 'src/components/prompt';
import Spinner, { useSpinner } from 'src/components/spinner';
import { defaultTableConfig, TableX } from 'src/components/tableX';
import TitleBar from 'src/components/title-bar';
import { useAuth } from 'src/context/AuthProvider';
import useDialog from 'src/hooks/useDialog';
import useI18nPrompt from 'src/hooks/useI18nPrompt';
import useMessage from 'src/hooks/useMessage';
import { ROUTE_PATH } from 'src/routes';
import ControlSwitch from '../controlled-input/Switch';
import DriveFileRenameOutlineSharpIcon from '@mui/icons-material/DriveFileRenameOutlineSharp';
import { DeleteForever } from '@mui/icons-material';
import { deletePrompt } from 'src/utils/prompts';

const CandidateList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { dialogState, openDialog, closeDialog } = useDialog();
  const { checkPermission } = useAuth();

  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();

  // For Form List
  const [triggerFetch, setTriggerFetch] = useState(false);
  // For Delete
  const { promptState, openPrompt, closePrompt } = useI18nPrompt();

  const refreshTable = () => {
    setTriggerFetch((prev) => !prev);
  };

  // const handleEdit = (data) => {
  //   openDialog(data);
  // };

  const onConfirmDelete = async (id) => {
    try {
      openSpinner();
      const res = await CandidateAPI.delete(id);
      refreshTable();
      showSuccessResponseMessage(res);
      closePrompt();
    } catch (error) {
      console.error(error);
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const onConfirmStatus = async (data) => {
    try {
      openSpinner();
      const res = await CandidateAPI.isActive(data._id, data);
      refreshTable();
      showSuccessResponseMessage(res);
    } catch (error) {
      console.log(error);
      showSuccessResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const tableSchema = [
    //Deadline Date
    {
      id: '_id',
      label: t('#'),
      width: '15%',
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return data._id;
      },
    },

    {
      id: 'firstName',
      width: '20%',
      label: t('candidate-name'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return data.firstName || data.lastName
          ? `${data?.firstName} ${data?.lastName}`
          : data.email;
      },
    },
    {
      id: 'createdAt',
      width: '20%',
      label: t('start-date'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return dayjs(data.createdAt).format('DD/MM/YYYY');
      },
    },

    {
      width: '10%',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <ControlSwitch
            name={`${data._id}_isActive`}
            defaultValue={data.isActive ?? false}
            onClick={(e) => {
              e.stopPropagation();
              onConfirmStatus(data);
            }}
          />
        );
      },
    },

    {
      label: t('actions'),
      width: '15%',
      sort: false,
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <Grid container spacing={1} mt='1px'>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation(); // keep on this page
                  if (checkPermission('delete', 'candidate')) {
                    navigate(
                      `${ROUTE_PATH.CREATE_CANDIDATE}?name=${
                        data.firstName || data.email
                      }&id=${data._id}`,
                    );
                  }
                }}
              >
                <DriveFileRenameOutlineSharpIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation(); // keep on this page
                  if (checkPermission('delete', 'candidate')) {
                    openPrompt(
                      deletePrompt({
                        title: ['delete-', 'Candidate'],
                        onConfirm: () => onConfirmDelete(data._id),
                        onCancel: () => closePrompt(),
                      }),
                    );
                  }
                }}
              >
                <DeleteForever />
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box>
      <TitleBar
        title={t('candidate-list')}
        btnTitle={t('create-new-candidate')}
        onBtnClick={() => {
          navigate(ROUTE_PATH.CREATE_CANDIDATE);
        }}
        disableButton={!checkPermission('create', 'candidate')}
      />
      <TableX
        triggerFetch={triggerFetch}
        schema={tableSchema}
        config={tableConfig}
        select={false}
        onRowClick={(data) => {
          if (checkPermission('update', 'candidate')) {
            navigate(
              `${ROUTE_PATH.CREATE_CANDIDATE}?name=${
                data.firstName || data.email
              }&id=${data._id}`,
            );
          }
        }}
      />
      <Spinner state={spinnerState} />
      <Prompt state={promptState} />
      <DepartmentPopupForm
        open={dialogState.open}
        data={dialogState.data}
        onClose={closeDialog}
        refreshTable={refreshTable}
      />
    </Box>
  );
};

const tableConfig = {
  ...defaultTableConfig,
  dataConfig: ['docs'],
  url: CandidateAPI.getURL,
  defaultSort: { name: 'asc' },
};

export default CandidateList;
