import { Grid, Typography } from '@mui/material';
import ProfilePictureEditor from '../controlled-input/profile-picture-editor';
import React from 'react';

import TitleContent from '../title-content';
import useTranslate from 'src/hooks/useTranslate';
const FormLogo = () => {
  const { t } = useTranslate();
  return (
    <Grid
      container
      spacing={3}
      display='flex'
      justifyContent='center'
      marginTop='30px'
    >
      <Grid item xs={12}>
        <TitleContent variant='title' title={'title-company-logo'} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} display='flex' justifyContent='center'>
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant='subTitle1'>
              {t('form-logo-description')}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <ProfilePictureEditor name='image' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FormLogo;
