import { Box } from '@mui/material';
import React from 'react';
import TitleBar from 'src/components/title-bar';
import FormCompanyInfo from 'src/components/company-info';

const CreateCompany = () => {
  return (
    <Box>
      <TitleBar disableButton={true} />
      <FormCompanyInfo />
    </Box>
  );
};

export default CreateCompany;
