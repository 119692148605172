import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import TitleContent from '../title-content';
import ControlledAutocomplete from 'src/components/controlled-input/AutoComplete';

const options = ['yes', 'no'];
const JobExpat = ({ name }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3} display='flex' justifyContent='center'>
      <Grid item xs={12} textAlign='center'>
        <TitleContent title='expats-support' />
      </Grid>
      <Grid
        item
        lg={6}
        md={8}
        xs={12}
        sx={{
          '& input': {
            fontFamily: 'Poppins',
          },
          '& label': {
            fontFamily: 'Poppins',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name='isHandleWorkPermit'
              getOptionLabel={(option) => t(option)}
              label={t('handle-work-permit')}
              placeholder={t('does-the-company-handle-the-work-permit?')}
              options={options}
              required
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name='isHandleRelocationCost'
              getOptionLabel={(option) => t(option)}
              label={t('handle-relocation-cost')}
              placeholder={t('does-the-company-handle-the-relocation-cost?')}
              options={options}
              required
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobExpat;
