import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import ControlledNumberInput from 'src/components/controlled-input/NumberInput';
import TitleContent from '../title-content';
import AutoCompleteChip from 'src/components/controlled-input/AutoCompleteChip';
import ControlledCheckBox from '../controlled-input/CheckBox';

const values = ['ReactJs', 'NodeJS'];
const JobStack = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} display='flex' justifyContent='center'>
      <Grid item xs={12} textAlign='center'>
        <TitleContent title='full-stack-developer' />
      </Grid>
      <Grid
        item
        lg={6}
        md={8}
        xs={12}
        sx={{
          '& input': {
            fontFamily: 'Poppins',
          },
          '& label': {
            fontFamily: 'Poppins',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledNumberInput
              name='numberOfMember'
              label={t('members')}
              placeholder={t('how-many-it-members-do-you-have-now?')}
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledNumberInput
              name='numberOfPosition'
              label={t('number-of-position')}
              placeholder={t('number-of-position-available-for-recruitment')}
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteChip
              name='techStack'
              options={values}
              label={t('skill')}
              freeSolo
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
              required
              // getOptionLabel={(option) => option.key}
              // getChipLabel={(option) => option.key}
              // isOptionEqualToValue={(option, value) => option === value}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCheckBox
              name='isExpat'
              label={t('available-for-expats')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobStack;
