import axios from './utils/custom-axios';

const S3 = {
  getPrivateFileURL: (key) => {
    return axios.get(`aws-s3/private-file-url?key=${key}`);
  },
  getFileUploadURL: (payload) => {
    return axios.post('aws-s3/file-upload-url', payload);
  },
  uploadToS3: (urlToUpload, fileToUpload, fileType) => {
    return axios.put(urlToUpload, fileToUpload, {
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      withCredentials: false,
    });
  },
  removeFiles: (keys, isPublic = false) =>
    axios.post('aws-s3/remove-file', { Keys: keys, isPublic }), // array of keys
  getBaseURL: () => {
    return axios.get('aws-s3/get-base-url');
  },
  getFileZip: async (id) => {
    return axios.get(`aws-s3/download?id=${id}`, {
      responseType: 'blob', // convert to blob
    });
  },
  getFileZipSingle: async (id, index) => {
    return axios.get(`aws-s3/download-single?id=${id}&index=${index}`, {
      responseType: 'blob', // convert to blob
    });
  },
};

export default S3;
