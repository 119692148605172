import { useTranslation } from 'react-i18next';
import { usePrompt } from 'src/components/prompt';

const usePromptI18nMiddleware = () => {
  const { t } = useTranslation();

  const translateKeyArray = (arrKeys) => {
    return Array.isArray(arrKeys)
      ? t(arrKeys[0], { value: t(arrKeys[1]) })
      : t(arrKeys);
  };

  const translatePrompt = (
    { title, message, buttons },
    { translate } = { translate: true },
  ) => {
    return {
      title: translate ? translateKeyArray(title) : title,
      message: translate ? translateKeyArray(message) : message,
      buttons: buttons.map((button) => {
        const text = translate ? translateKeyArray(button.text) : button.text;
        return { ...button, text };
      }),
    };
  };

  return translatePrompt;
};

const useI18nPrompt = (props) => {
  const translatePrompt = usePromptI18nMiddleware();
  const { promptState, openPrompt, closePrompt } = usePrompt({
    middleware: translatePrompt,
    ...props,
  });
  return { promptState, openPrompt, closePrompt };
};

export default useI18nPrompt;
