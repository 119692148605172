/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table,
  InputAdornment,
  TextField,
  Typography,
  TableContainer,
  Box,
  Grid,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'debounce';

import defaultTableConfig from './config';
import PaginationX from './pagination';
import TableHeadX from './head';
import readObjectWithArrayKeys from './utils/read-object-value-with-array-keys';
import TableBodyX from './body';
import combineObjectKeyValue from './utils/combine-object-key-value';
import Root from './style';

const TableX = ({
  schema,
  config,
  triggerFetch,
  render,
  renderFilterInput,
  onRowClick,
  minHeight,
  defaultFilter,
  disableSearch,
  select = true,
  onSetDataList,
}) => {
  const {
    url,
    pageNumQuery,
    limitSizeQuery,
    dataConfig,
    sortByQuery,
    defaultSort = {},
    searchQuery,
    initialDate,
  } = config;
  const [sort, setSort] = useState(defaultSort);
  const [dataList, setDataList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [search, setSearch] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);
  const [dateRange, setDateRange] = useState({
    from: initialDate?.from || null,
    to: initialDate?.to || null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    config.rowsPerPageOptions[0] || 25,
  );

  useEffect(() => {
    setPage(1);
    setFilter(defaultFilter);
  }, [defaultFilter]);

  const fetchData = (pageNum = page, searchText = search) => {
    setIsLoading(true);
    axios
      .get(
        `${url}?${searchQuery}=${searchText}&${pageNumQuery}=${pageNum}&${limitSizeQuery}=${rowsPerPage}&${sortByQuery}=${combineObjectKeyValue(
          sort,
        )}&filter=${combineObjectKeyValue(filter)}${
          renderFilterInput
            ? `${
                dateRange.from
                  ? `&fromDate=${dateRange.from}&toDate=${dateRange.to} `
                  : ''
              }`
            : ''
        }`,
      )
      .then((response) => {
        const data = dataConfig
          ? readObjectWithArrayKeys(response.data, dataConfig)
          : [];
        onSetDataList ? onSetDataList(data, setDataList) : setDataList(data);
        setTotalActive(response.data.totalActive);
        setTotalDocs(response.data.totalDocs);
        setResponseData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setDataList([]);
        setTotalDocs(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, triggerFetch, sort, filter, dateRange]);

  const debounceSearch = useCallback(
    debounce((text) => {
      fetchData(1, text);
      setSearch(text);
    }, 500),
    [page, rowsPerPage, sort, filter, dateRange],
  );

  const handleSort = (event, property) => {
    const isSorting = property in sort;
    let order = null;
    if (isSorting) {
      if (sort[property] === 'asc') {
        order = 'desc';
      } else if (sort[property] === 'desc') {
        return setSort((prev) => {
          const newSort = { ...prev };
          delete newSort[property];
          setSort(newSort);
        });
      } else {
        order = 'asc';
      }
    } else {
      order = 'asc';
    }
    setSort(() => ({ [property]: order }));
  };

  const handleChangePagination = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleSelectMultiple = (e) => {
    if (e.target.checked) {
      setSelected(dataList);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (e, row) => {
    e.stopPropagation();
    if (e.target.checked) {
      setSelected((prev) => [...prev, row]);
    } else {
      const filterSelected = selected.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(row),
      );
      setSelected(filterSelected);
    }
  };

  return (
    <Root>
      <Grid container direction='column' sx={{ minHeight }} spacing={2}>
        <Grid item sx={{ marginBottom: '30px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: renderFilterInput ? 'space-between' : 'flex-end',
              border: '2px',
            }}
          >
            {!disableSearch && (
              <TextField
                size='normal'
                variant='outlined'
                fullWidth={false}
                placeholder='Search Job'
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                }}
                onChange={(e) => debounceSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon
                        sx={{
                          width: '30px',
                          height: '30px',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            )}{' '}
            {renderFilterInput &&
              renderFilterInput({
                setFilter,
                setDateRange,
                dateRange,
                dataList,
                search,
                sort: `${sortByQuery}//${combineObjectKeyValue(sort)}`,
                filter: combineObjectKeyValue(filter),
              })}
          </div>
        </Grid>
        <Grid item xs>
          <TableContainer>
            <Table className='tableX'>
              <TableHeadX
                dataList={dataList}
                selected={selected}
                handleSelectMultiple={handleSelectMultiple}
                sort={sort}
                handleSort={handleSort}
                schema={schema}
                select={select}
              ></TableHeadX>
              <TableBodyX
                selected={selected}
                handleSelect={handleSelect}
                handleClick={onRowClick}
                dataList={dataList}
                schema={schema}
                select={select}
              ></TableBodyX>
            </Table>
            {!isLoading && dataList.length === 0 ? (
              <Box mt={3} mb={3}>
                <Typography variant='body' align='center'>
                  No Item Available
                </Typography>
              </Box>
            ) : null}
          </TableContainer>
        </Grid>
        <Grid item mb={10}>
          {render && <Box mt={1}>{render(selected, responseData)}</Box>}
          <PaginationX
            totalActive={totalActive}
            page={page}
            total={totalDocs}
            rowsPerPageOptions={config.rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            onChangePagination={handleChangePagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={Math.floor((totalDocs + rowsPerPage - 1) / rowsPerPage)}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export { TableX, defaultTableConfig };
