import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Stack,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import imageCompression from 'browser-image-compression';

const avatarSize = 300;

const style = {
  iconButton: (theme, num, scale) => ({
    color: scale === num ? theme.palette.grey[400] : 'black',
    transition: '0.1s linear',
  }),
  dialogTitle: { position: 'absolute', top: 8, right: 8 },
  dialogContentGrid: {
    justifyContent: { xs: 'center', md: 'flex-start' },
    height: { xs: '100%', md: 'auto' },
  },
};

const ProfileEditor = ({
  open,
  onClose,
  image,
  onBrowse,
  title,
  setImage,
  onImageSave,
}) => {
  const [zoom, setZoom] = useState(0);

  const matchSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const editorRef = useRef();

  const increaseZoom = () =>
    setZoom((prev) => (prev >= 90 ? 100 : (prev += 10)));

  const decreaseZoom = () => setZoom((prev) => (prev <= 10 ? 0 : (prev -= 10)));

  useEffect(() => {
    setZoom(0);
  }, [image.file]);

  const handleSave = async () => {
    try {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const canvasImage = canvas.toDataURL();
      const imageFile = await imageCompression.getFilefromDataUrl(canvasImage);
      onClose();
      setImage((prev) => {
        const newImage = {
          file: imageFile,
          blob: canvasImage,
        };
        return { ...prev, ...newImage };
      });
      onImageSave({ file: imageFile });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSlideChange = (_, newValue) => setZoom(newValue);

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullScreen={matchSM}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        <IconButton onClick={onClose} sx={style.dialogTitle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction='column'
          spacing={1}
          sx={style.dialogContentGrid}
        >
          <Grid item container justifyContent='center'>
            <AvatarEditor
              ref={(editor) => (editorRef.current = editor)}
              image={image.file}
              width={avatarSize}
              height={avatarSize}
              borderRadius={avatarSize / 2}
              color={[255, 255, 255, 0.6]}
              scale={1 + zoom / 100}
            />
          </Grid>
          <Grid item>
            <Stack spacing={2} direction='row' alignItems='center'>
              <IconButton disabled={zoom === 0} onClick={decreaseZoom}>
                <RemoveIcon sx={(theme) => style.iconButton(theme, 0, zoom)} />
              </IconButton>
              <Slider value={zoom} onChange={handleSlideChange} />
              <IconButton disabled={zoom === 100} onClick={increaseZoom}>
                <AddIcon sx={(theme) => style.iconButton(theme, 100, zoom)} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{ width: '100%' }}
          direction='row'
          justifyContent='space-between'
        >
          <Button type='button' onClick={onBrowse} disableElevation>
            Browse
          </Button>
          <Button
            type='button'
            onClick={handleSave}
            variant='contained'
            disableElevation
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileEditor;
