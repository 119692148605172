import login from './login.json';
import common from './common.json';
import authenticate from './authenticate.json';
import user from './user.json';
import rolePermission from './role-permission.json';
import job from './job.json';
import companyInfo from './company-info.json';
import candidate from './candidate.json';
import company from './company.json';
import profile from './profile.json';

export const TRANSLATIONS_EN = {
  ...login,
  ...authenticate,
  ...user,
  ...common,
  ...rolePermission,
  ...job,
  ...companyInfo,
  ...candidate,
  ...company,
  ...profile,
};
