import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import CreateCandidate from 'src/components/candidate/create';

const CreateCandidatePage = () => {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <CreateCandidate />
    </FormProvider>
  );
};

export default CreateCandidatePage;
