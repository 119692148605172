import { Box } from '@mui/system';
import React from 'react';
import { useController } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const style = {
  container: (theme) => ({
    '& .react-tel-input': {
      fontFamily: 'inherit',
      '& .form-control': {
        width: '100%',
        padding: '9.41px 14px 9.41px 58px',
        backgroundColor: 'white',
        borderStyle: 'solid',
        boxShadow: 'none',
        // borderColor: theme.palette.error.main,
        fontSize: '16px',
        fontFamily: 'inherit',
        '&:focus': {
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
          borderColor: theme.palette.action.active,
        },
      },
    },
  }),
  error: (theme) => ({
    '& .react-tel-input': {
      '& .form-control': {
        borderColor: theme.palette.error.main,
      },
    },
  }),
};

const ControlledPhoneNumberInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = '',
    label,
    placeholder = label?.toUpperCase(), //using label as default value
    ...textFieldProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    control, //optional when use inside formContext
    rules: { required },
  });

  return (
    <Box sx={style.container}>
      <Box sx={error && style.error}>
        <PhoneInput
          defaultMask='.. ... ... ... ...'
          inputProps={{ ref, ...inputProps }}
          specialLabel=''
          value={value}
          country={'kh'}
          onChange={onChange}
          variant='outlined'
          countryCodeEditable={false}
          fullWidth
          placeholder={placeholder}
          {...textFieldProps}
        />
      </Box>
    </Box>
  );
};

export default ControlledPhoneNumberInput;
