import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  addCustomImageUploader,
  addRemoveImageListener,
  filterImageType,
  filterRemoveLocalImage,
} from './utils';
import { useController } from 'react-hook-form';
import { Box } from '@mui/material';

const ControlledCKEditor = ({ name, control, height = '300px', required }) => {
  const [content, setContent] = useState('');
  const [images, setImages] = useState({ upload: [], remove: [] });
  const renderRef = useRef(false);
  // using react hook form useController as value state
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    defaultValue: '',
    name,
    control, //optional when use inside formContext
    rules: {
      validate: (value) => {
        if (required) {
          if (!value) return false;
          return value.content !== '<p></p>' || value.content === '';
        }
        return true;
      },
    },
  });

  useEffect(() => {
    if (renderRef.current === false) {
      if (value !== content || value === '') {
        setContent(value?.content || value);
        renderRef.current = true;
      }
    }
  }, [value]);

  useEffect(() => {
    onChange({ content: content || '<p></p>', images });
  }, [images, content]);

  const handleChange = useCallback(
    (_, editor) => {
      const data = editor.getData();
      setContent(data);
    },
    [setContent],
  );

  const onReady = useCallback(
    (editor) => {
      addCustomImageUploader(editor, ({ blob, file }) => {
        setImages((prev) => ({
          ...prev,
          upload: [...prev.upload, { blob, file }],
        }));
      });

      addRemoveImageListener(editor, (removeImages) => {
        const { removeLocalImages, removeUploadedImages } =
          filterImageType(removeImages);

        // console.log(state.images, removeLocalImages, removeImages);
        if (removeUploadedImages.length > 0 || removeLocalImages.length > 0) {
          setImages((prev) => ({
            upload: filterRemoveLocalImage(prev.upload, removeLocalImages),
            remove: [...prev.remove, ...removeUploadedImages],
          }));
        }
      });
    },
    [setImages],
  );

  return (
    <Box
      sx={[
        {
          '& .ck-editor__editable': {
            height,
          },
        },
        error && {
          '& .ck-editor__editable.ck.ck-content': {
            borderColor: (theme) => theme.palette.error.main,
          },
        },
      ]}
    >
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={handleChange}
        onReady={onReady}
        error={!!error}
      />
    </Box>
  );
};

export default ControlledCKEditor;
