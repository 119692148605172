import { TextField } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

const ControlledTextField = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = '',
    variant = 'outlined',
    size,
    label,
    fullWidth = true,
    rules,
    component: Component = TextField,
    onChange,
    ...textFieldProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucOnChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, ...rules },
    defaultValue,
  });

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    ucOnChange(value);
  };

  return (
    <Component
      variant={variant}
      fullWidth={fullWidth}
      label={label}
      size={size}
      value={value}
      onChange={onChange ? (data) => onChange(data, ucOnChange) : handleChange}
      inputRef={ref}
      error={!!error}
      {...textFieldProps}
      {...inputProps}
    />
  );
};

export default ControlledTextField;
