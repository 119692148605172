import axios from 'axios';

const cityAPI = {
  get: (params) => {
    return axios.get('city', { params });
  },
  getURL: 'city',
};

export default cityAPI;
