import React, { useState } from 'react';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs';
import Spinner, { useSpinner } from 'src/components/spinner';
import ImageUploader from 'src/utils/ImageUploader';
import { useTranslation } from 'react-i18next';
import CandidateAPI from 'src/api/candidate';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import useI18nPrompt from 'src/hooks/useI18nPrompt';
import Prompt from 'src/components/prompt';
import { deletePrompt } from 'src/utils/prompts';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import downloadBlobFile from 'src/utils/downloadBlobFile';
import useMessage from 'src/hooks/useMessage';
import S3 from 'src/api/s3';

const ViewFileUpload = ({ defaultPreviews, data }) => {
  const { t } = useTranslation();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();
  const { promptState, openPrompt, closePrompt } = useI18nPrompt();
  const [getParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const handleUpload = new ImageUploader();

  useEffect(() => {
    if (getParams.get('id')) {
      getCv();
    }
  }, [defaultPreviews]);

  const getCv = async () => {
    const { data } = await CandidateAPI.getById(getParams.get('id'));
    setFiles(data.data.cv);
  };
  const handleRemoveImage = (index) => {
    setFiles((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  const downloadFileZip = async (index) => {
    try {
      openSpinner();
      const res = await S3.getFileZipSingle(getParams.get('id'), index);
      downloadBlobFile({
        filename:
          res.data.type === 'text/xml'
            ? 'ITJob_Cambodia.zip'
            : `ITJob_Cambodia.${res.data.type.split('/')[1]}`, // is require rename (file and extension)
        data: res.data, // file blob
      });
      showSuccessResponseMessage(res, t('download-successfully'));
    } catch (error) {
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const onSubmit = async (index, item) => {
    try {
      openSpinner();
      handleUpload.removeImage(item);

      const [response] = await Promise.all([
        CandidateAPI.deleteCV(getParams.get('id'), item),
        handleUpload.exec(),
      ]);
      handleRemoveImage(index);
      showSuccessResponseMessage(response);
      closePrompt();
    } catch (error) {
      console.log(error);
      showSuccessResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  return (
    <Grid container rowSpacing={2} mb={5}>
      <Grid item xs={12}>
        <Typography variant='subTitle' ml={4}>
          You can drop 5 files.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <Grid
            container
            py={2}
            px={4}
            display='flex'
            justifyContent='space-between'
          >
            <Grid item xs={4}>
              <Typography variant='subTitle'>{t('created-date')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subTitle'>{t('cv-title')}</Typography>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <Typography variant='subTitle'>{t('action')}</Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              {files?.map((item, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item xs={4}>
                      <Typography>
                        {dayjs(item?.createdAt).format('DD MMM YYYY') || 'n/a'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{item.name}</Typography>
                    </Grid>
                    <Grid item xs={1} textAlign='end'>
                      <IconButton
                        sx={{
                          svg: {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                        value='download'
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadFileZip(index);
                          // navigate(`${ROUTE_PATH.CREATE_JOB}/${data._id}`);
                        }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        sx={{
                          svg: {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                        onClick={() => {
                          openPrompt(
                            deletePrompt({
                              title: ['delete-', 'CV File'],
                              onConfirm: () => onSubmit(index, item),
                              onCancel: () => closePrompt(),
                            }),
                          );
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Prompt state={promptState} />
      <Spinner state={spinnerState} />
    </Grid>
  );
};

export default ViewFileUpload;
