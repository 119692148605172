import { Autocomplete, TextField } from '@mui/material';
import { useController } from 'react-hook-form';

const ControlledAutocomplete = ({
  name,
  control,
  required,
  defaultValue = null,
  options,
  placeholder,
  variant,
  label,
  onChange,
  disablePortal,
  autoFocus,
  ...props
}) => {
  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucOnChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required },
    defaultValue,
  });

  return (
    <Autocomplete
      autoHighlight
      disablePortal={disablePortal}
      onChange={(_, data, reason) => {
        if (onChange) {
          onChange(data, ucOnChange);
        } else {
          ucOnChange(data);
        }
      }}
      options={options || []}
      {...inputProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          placeholder={placeholder}
          label={label}
          variant={variant}
          inputRef={ref}
          error={!!error}
          autoFocus={autoFocus}
        />
      )}
      {...props}
      value={value}
    />
  );
};
export default ControlledAutocomplete;
