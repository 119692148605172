import axios from './utils/custom-axios';

const Auth = {
  login: (data) => {
    return axios.post('auth/login', data);
  },
  getMe: () => {
    return axios.get('auth/users/me');
  },
  logout: () => {
    return axios.post('auth/logout');
  },
  register(data) {
    return axios.post('auth/register', data);
  },
  verifyPassword(password) {
    return axios.post('auth/users/me/verify-password', { password });
  },
  forgotPassword: (data) => axios.post('/auth/forgot-password', data),
  resetPassword: (data) => axios.post('/auth/reset-password', data),
  checkEmailOrPhoneAvailable({ emailOrPhone, available = true, workspaceId }) {
    return axios.get('auth/users/available', {
      params: {
        emailOrPhone,
        workspaceId,
        ...(available ? { available: true } : {}),
      },
    });
  },
  requestEmailOTP(email, reset = true) {
    return axios.post('auth/request-otp', { email, reset });
  },
  updateMe(data) {
    return axios.put('auth/users/me', data);
  },
};

export default Auth;
