import { Grid, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { useWatch } from 'react-hook-form';
import useTranslate from 'src/hooks/useTranslate';
import ControlledAutocomplete from '../controlled-input/AutoComplete';
import ControlledCKEditor from '../controlled-input/ckeditor';
import ControlledDateInput from '../controlled-input/DateInput';
import TitleContent from '../title-content';

const optionsWorkDay = ['No', 'Half Day', 'Full Day'];
const FormBenefits = ({ control, ...props }) => {
  const { t } = useTranslate();

  const timeStart = dayjs(
    useWatch({
      name: 'timeStart',
    }),
  ).unix();
  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      display='flex'
      marginTop='30px'
    >
      <Grid item xs={12}>
        <TitleContent title={'title-benefits-culture'} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} textAlign='center'>
            <Typography variant='subtitle1'>
              {t('form-benefits-culture-description')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ControlledDateInput
              name='timeStart'
              type='time'
              label={t('office-hour-start')}
              placeholder={t('office-hour-start')}
              required
              backgroundColor='white'
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledDateInput
              name='timeEnd'
              type='time'
              label={t('office-hour-end')}
              placeholder={t('office-hour-end')}
              rules={{
                validate: {
                  checkTime: (v) => timeStart < dayjs(v).unix(),
                },
              }}
              required
              backgroundColor='white'
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              getOptionLabel={(option) => t(option)}
              name='saturdayWork'
              label={t('do-you-work-on-saturday?')}
              placeholder={t('do-you-work-on-saturday?')}
              options={optionsWorkDay}
              required
              sx={{
                backgroundColor: 'white',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>{t('ckdEditor-label')}</Typography>
            <ControlledCKEditor
              name='benefitAndCulture'
              height='600px'
              placeholder={t('benefits-and-culture')}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FormBenefits;
