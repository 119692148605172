import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cityAPI from 'src/api/city';
import ControlledTextField from 'src/components/controlled-input/TextField';
import ControlledAutocomplete from '../controlled-input/AutoComplete';
import AutoCompleteChip from 'src/components/controlled-input/AutoCompleteChip';
import TitleContent from '../title-content';

const values = ['IT Industry', 'Telecom', 'Education'];

const FormCompanyInformation = () => {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  useEffect(() => {
    const getCities = async () => {
      const res = await cityAPI.get();
      res.data.data && setCities(res.data.data);
    };
    getCities();
  }, []);
  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      display='flex'
      marginTop='30px'
    >
      <Grid item xs={12}>
        <TitleContent title={'title-company-information'} />
      </Grid>
      <Grid item xs={12} textAlign='center'>
        <Typography variant='subtitle1'>
          {t('form-company-information-description')}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name='name'
              label={t('company-name')}
              placeholder={t('company-name')}
              required
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <AutoCompleteChip
              name='industry'
              options={values}
              label={t('industry')}
              freeSolo
              required
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <ControlledAutocomplete
              name={'city'}
              getOptionLabel={(option) => {
                return option.name;
              }}
              isOptionEqualToValue={(option, value) => {
                return option.name === value.name;
              }}
              label={t('city')}
              placeholder={t('city')}
              options={cities}
              required
              sx={{
                backgroundColor: 'white',
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <ControlledTextField
              name='address'
              label={t('location')}
              placeholder={t('location')}
              required
              sx={{
                '.MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FormCompanyInformation;
