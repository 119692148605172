import { styled } from '@mui/system';

// custom style table
const TableContainer = styled('div')(({ theme }) => ({
  '& .tableX th': {
    textWrap: 'no-wrap',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& span': {
      whiteSpace: 'nowrap',
    },
  },
  '& .tableX td': {
    cursor: 'pointer',
    borderBottom: '0px',
  },
  '& .tableX tr': {
    cursor: 'pointer',
  },
  '& .tableX table': {
    marginTop: '40px',
  },
  // action type cell
  '& .tableX-action-cell': {
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer',
  },
}));

export default TableContainer;
