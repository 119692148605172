import React, { useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import Prompt from 'src/components/prompt';
import { TableX, defaultTableConfig } from 'src/components/tableX';
import TitleBar from 'src/components/title-bar';
import { useTranslation } from 'react-i18next';
import useDialog from 'src/hooks/useDialog';
import Spinner, { useSpinner } from 'src/components/spinner';
import useMessage from 'src/hooks/useMessage';
import useI18nPrompt from 'src/hooks/useI18nPrompt';
import { useAuth } from 'src/context/AuthProvider';
import DepartmentPopupForm from 'src/components/department/department-popup-form';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'src/routes';
import dayjs from 'dayjs';
import { DeleteForever } from '@mui/icons-material';
import DriveFileRenameOutlineSharpIcon from '@mui/icons-material/DriveFileRenameOutlineSharp';
import CompanyAPI from 'src/api/company';
import ControlSwitch from 'src/components/controlled-input/Switch';
import { useForm } from 'react-hook-form';

const CompanyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { dialogState, openDialog, closeDialog } = useDialog();
  const { checkPermission } = useAuth();

  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();

  // For Form List
  const [triggerFetch, setTriggerFetch] = useState(false);
  // For Delete
  const { promptState, openPrompt, closePrompt } = useI18nPrompt();

  const refreshTable = () => {
    setTriggerFetch((prev) => !prev);
  };

  // const handleEdit = (data) => {
  //   openDialog(data);
  // };

  const onConfirmDelete = async (data) => {
    try {
      openSpinner();

      refreshTable();
      showSuccessResponseMessage();
      closePrompt();
    } catch (error) {
      console.error(error);
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const onConfirmStatus = async (data) => {
    try {
      openSpinner();
      const res = await CompanyAPI.isActive(data._id, data);
      refreshTable();
      showSuccessResponseMessage(res);
    } catch (error) {
      console.log(error);
      showSuccessResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const tableSchema = [
    //Deadline Date
    {
      id: 'createdAt',
      label: t('created-date'),
      width: '15%',
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return dayjs(data.createdAt).format('DD MMM YYYY');
      },
    },

    {
      id: 'name',
      width: '20%',
      label: t('company-name'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return data.name || 'n/a';
      },
    },
    {
      id: 'city',
      width: '20%',
      label: t('city´s-company'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return data.city || 'n/a';
      },
    },
    {
      width: '10%',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <ControlSwitch
            control={methods.control}
            name={`${data._id}_isActive`}
            defaultValue={data.isActive ?? false}
            onClick={(e) => {
              e.stopPropagation();
              onConfirmStatus(data);
            }}
          />
        );
      },
    },
    {
      label: t('actions'),
      width: '15%',
      sort: false,
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <Grid container spacing={1} mt='1px'>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                  },
                }}
              >
                <DriveFileRenameOutlineSharpIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                  },
                }}
              >
                <DeleteForever
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box>
      <TitleBar
        title={t('company-list')}
        btnTitle={t('create-new-company')}
        onBtnClick={() => {
          navigate(ROUTE_PATH.CREATE_COMPANY);
        }}
        disableButton={
          true
          // !checkPermission('create', 'company')
        }
      />

      <TableX
        triggerFetch={triggerFetch}
        schema={tableSchema}
        config={tableConfig}
        select={false}
      />
      <Spinner state={spinnerState} />
      <Prompt state={promptState} />
      <DepartmentPopupForm
        open={dialogState.open}
        data={dialogState.data}
        onClose={closeDialog}
        refreshTable={refreshTable}
      />
    </Box>
  );
};

const tableConfig = {
  ...defaultTableConfig,
  dataConfig: ['docs'],
  url: CompanyAPI.url,
  defaultSort: { name: 'asc' },
};

export default CompanyPage;
