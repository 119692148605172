import { useSelector, useDispatch } from 'react-redux';

import { selectIsLoading, startLoading as startLoadingInRedux, stopLoading as stopLoadingInRedux } from 'src/redux/ui-redux/uiSlice';

const useUI = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectIsLoading)

  const startLoading = () => {
    dispatch(startLoadingInRedux())
  }

  const stopLoading = () => {
    dispatch(stopLoadingInRedux())
  }

  return {
    isLoading,
    startLoading,
    stopLoading
  }
}

export default useUI