import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthProvider';

export const useAccessControl = () => {
  const { loading, checkPermission } = useAuth();

  const checkAccessControl = (execute, feature) => {
    if (loading) return false;
    return checkPermission(execute, feature);
  };

  return checkAccessControl;
};

const AccessControl = ({ children, on, render, execute, feature }) => {
  const checkAccessControl = useAccessControl();

  let isGranted = false;

  // use execute and feature prop
  if (execute && feature) {
    isGranted = checkAccessControl(execute, feature);
  }
  // use short hand on prop
  else if (on) {
    const [action, featureName] = on.split(':');

    if (!action || !featureName) {
      console.error('on props must be a string format "execute:feature"');
      return null;
    }

    isGranted = checkAccessControl(action, featureName);
  }

  if (render) {
    return render(isGranted);
  }

  if (isGranted === false) {
    return null;
  }
  return children;
};

export const AccessControlRoute = ({
  children,
  execute,
  feature,
  redirect = '/dashboard',
}) => {
  const { user } = useAuth();
  // const checkAccessControl = useAccessControl();
  let isGranted = false;

  // use execute and feature prop
  if (execute && feature) {
    if (
      (feature === 'candidate' || feature === 'company') &&
      user?.isSuperUser
    ) {
      isGranted = true;
    }
    if (feature !== 'candidate' && feature !== 'company') {
      isGranted = true;
    }
  }

  if (isGranted === false) {
    return <Navigate to={redirect} />;
  }
  return children;
};

export default AccessControl;
