import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthProvider';

//  auth = true : if want redirect when user is login
//  auth = false : if want redirect when use is not login

// isCompleted = true :  can't redirect  dashboard
// isCompleted = false : if want  redirect dashboard

const PrivateRoute = ({
  children,
  auth = false,
  redirect,
  isCompleted = true,
}) => {
  const { loading, user } = useAuth();
  if (loading) return <LinearProgress />;

  let isAuth = false;

  if (user) {
    isAuth = true;
  }

  if (loading === false && isAuth !== auth) {
    if (!auth && isCompleted && !user?.workspace.isCompleted)
      return <Navigate to={'/company'} />;
    else if (!isCompleted && user?.workspace.isCompleted)
      return <Navigate to={'/dashboard'} />;

    return children;
  } else {
    return <Navigate to={redirect} />;
  }
};

export default PrivateRoute;
