import { useState } from 'react';
import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

function GoogleMapPicker({ onChange, value } = {}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = useState();

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function onSuccess(location) {
        setCenter({
          lat: location?.coords?.latitude,
          lng: location?.coords?.longitude,
        });
      },
      function onError() {
        setCenter(value);
      },
    );
  };

  const onLoad = function callback(map) {
    setMap(map);
    if (value) {
      setCenter(value);
    } else {
      getCurrentLocation();
    }
  };

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={16}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onCenterChanged={() => {
        if (!map) return;
        setCenter(map.getCenter());
        onChange &&
          onChange({
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          });
      }}
    >
      <ComponentChild position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

const ComponentChild = ({ position }) => {
  return <Marker position={position} />;
};

export default React.memo(GoogleMapPicker);
