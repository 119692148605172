import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import globalStyle from './global';

export const primary = {
  lighter: '#cf4a55',
  light: '#d45e68',
  main: '#C63540',
  highlight: '#03e8fc',
  dark: '#792028',
  darker: '#51151a',
};

let theme = createTheme({
  palette: {
    primary,
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#D73027',
    },
    navbar: {
      background: 'white',
    },
    drawer: {
      background: 'white',
      hover: {
        backgroundColor: primary.light,
        color: 'black',
      },
      selected: {
        backgroundColor: primary.main,
        color: 'white',
        '& svg': { color: 'white' },
      },
      subSelected: {
        backgroundColor: primary.lighter,
        color: 'white',
      },
      textColor: 'black',
      iconColor: primary.main,
    },
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
  },
  components: {
    MuiCssBaseline: { styleOverrides: {} },
    MuiTypography: {
      styleOverrides: {
        title: {
          fontSize: '45px',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
        },
        subTitle: {
          fontSize: '16px !important',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          textTransform: 'capitalize',
          lineHeight: 'unset',
        },
        h1: {
          fontSize: '18px !important',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          textTransform: 'capitalize',
          lineHeight: 'unset',
        },
        h2: {
          fontSize: '15px !important',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
        },
        h3: {
          fontSize: '12px !important',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '28px',
          boxShadow:
            '1px 1px 18px 1px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
        elevation2: {
          boxShadow:
            '1px 1px 18px 1px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
      },
    },
  },
});

//overwrite globalStyle
theme.components.MuiCssBaseline.styleOverrides = globalStyle(theme);

theme = responsiveFontSizes(theme);

export default theme;
