import axios from 'axios';

const CandidateAPI = {
  get: (params) => {
    return axios.get('candidate/get-by-workspace', { params });
  },
  getById: (id) => {
    return axios.get(`candidate/${id}`);
  },
  create: (payload) => {
    return axios.post('candidate', payload);
  },
  update: (id, payload) => {
    return axios.put(`candidate/${id}`, payload);
  },
  uploadCV: (id, payload) => {
    return axios.put(`candidate/upload-cv/${id}`, payload);
  },
  deleteCV: (id, payload) => {
    return axios.put(`candidate/delete-cv/${id}`, payload);
  },
  isActive: (id, payload) => {
    return axios.put(`candidate/isActive/${id}`, payload);
  },
  delete: (id) => {
    return axios.delete(`candidate/${id}`);
  },
  getURL: 'candidate/list',
};

export default CandidateAPI;
