import axios from 'axios';

const JobAPI = {
  get: (params) => {
    return axios.get('job/get-by-workspace', { params });
  },
  getById: (id) => {
    return axios.get(`job/get-by-workspace/${id}`);
  },
  create: (payload) => {
    return axios.post('job', payload);
  },
  update: (id, payload) => {
    return axios.put(`job/${id}`, payload);
  },
  isPublished: (id, payload) => {
    return axios.put(`job/isPublished/${id}`, payload);
  },
  delete: (id) => {
    return axios.delete(`job/${id}`);
  },
  getURL: 'job/get-by-workspace',
};

export default JobAPI;
