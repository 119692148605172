import { Controller } from 'react-hook-form';
import AutoCompleteChip from '../input/AutoCompleteChip';

const ControlledAutocompleteChip = ({
  name,
  control,
  rules,
  placeholder,
  label,
  options,
  required,
  defaultValue = [],
  ...autoCompleteProps
}) => {
  return (
    <Controller
      control={control}
      rules={{
        ...(required ? { validate: (value) => value?.length > 0 } : {}),
        ...rules,
      }}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, ref, ...props },
        fieldState: { error },
      }) => {
        return (
          <AutoCompleteChip
            getChipLabel={(option) => option}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={onChange}
            options={options}
            value={value}
            ref={ref}
            error={error}
            label={label}
            placeholder={placeholder}
            {...props}
            {...autoCompleteProps}
          />
        );
      }}
    ></Controller>
  );
};
export default ControlledAutocompleteChip;
