import { Box, Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useSearchParams } from 'react-router-dom';
import DepartmentPopupForm from 'src/components/department/department-popup-form';
import Prompt from 'src/components/prompt';
import Spinner, { useSpinner } from 'src/components/spinner';
import { defaultTableConfig, TableX } from 'src/components/tableX';
import TitleBar from 'src/components/title-bar';
import useDialog from 'src/hooks/useDialog';
import useI18nPrompt from 'src/hooks/useI18nPrompt';
import useMessage from 'src/hooks/useMessage';
import ApplyJobAPI from 'src/api/apply-job';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import S3 from 'src/api/s3';
import downloadBlobFile from 'src/utils/downloadBlobFile';

const JobDetail = () => {
  const { t } = useTranslation();
  const [getParams] = useSearchParams();

  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { dialogState, openDialog, closeDialog } = useDialog();

  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();

  // For Form List
  const [triggerFetch, setTriggerFetch] = useState(false);
  // For Delete
  const { promptState, openPrompt, closePrompt } = useI18nPrompt();

  const refreshTable = async () => {
    setTriggerFetch((prev) => !prev);
  };

  const downloadFileZip = async (data) => {
    try {
      openSpinner();
      const res = await S3.getFileZip(data.candidate.id);

      downloadBlobFile({
        filename:
          res.data.type === 'text/xml'
            ? 'ITJob_Cambodia.zip'
            : `ITJob_Cambodia.${res.data.type.split('/')[1]}`, // is require rename (file and extension)
        data: res.data, // file blob
      });
      showSuccessResponseMessage(res, 'download-successfully');
    } catch (error) {
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const tableConfig = {
    ...defaultTableConfig,
    dataConfig: ['docs'],
    url: ApplyJobAPI.getApplyJob(getParams.get('id')),
    defaultSort: { name: 'asc' },
  };

  const tableSchema = [
    //Deadline Date
    {
      id: 'createdAt',
      label: t('applied-date'),
      width: '15%',
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return dayjs(data.createdAt).format('DD MMM YYYY');
      },
    },

    {
      id: 'interestedIn',
      width: '20%',
      label: t('interested-in'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return data.interestedIn;
      },
    },

    {
      id: 'email',
      width: '20%',
      label: t("candidate's-email"),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return data.candidate.email;
      },
    },

    // expected Salary
    {
      id: 'expectedSalary',
      width: '20%',
      label: t('expect-salary'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <NumericFormat
            value={data.expectedSalary}
            displayType={'text'}
            thousandSeparator={true}
            suffix={' USD'}
          />
        );
      },
    },
    {
      label: t('actions'),
      width: '10%',
      sort: false,
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <Grid container spacing={1} mt='1px'>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                  },
                }}
                value='download'
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFileZip(data);
                  // navigate(`${ROUTE_PATH.CREATE_JOB}/${data._id}`);
                }}
              >
                <CloudDownloadIcon />
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box>
      <TitleBar title={getParams.get('job')} disableButton={true} />

      <TableX
        triggerFetch={triggerFetch}
        schema={tableSchema}
        config={tableConfig}
        select={false}
        disableSearch={true}
        // onRowClick={(data) => {
        //   navigate(`${ROUTE_PATH.JOB_DETAIL}/${data.candidate.id}`);
        // }}
      />
      <Spinner state={spinnerState} />
      <Prompt state={promptState} />
      <DepartmentPopupForm
        open={dialogState.open}
        data={dialogState.data}
        onClose={closeDialog}
        refreshTable={refreshTable}
      />
    </Box>
  );
};

export default JobDetail;
