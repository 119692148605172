import { Grid, Typography } from '@mui/material';

const InputLabel = ({ label, inputId, children, spacing = 1, bold = true }) => {
  return (
    <Grid container direction='column' spacing={spacing}>
      <Grid item>
        <label htmlFor={inputId}>
          <Typography variant={'body2'} sx={{ fontWeight: bold && '500' }}>
            {label}
          </Typography>
        </label>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default InputLabel;
