import { useTranslation } from 'react-i18next';

const useTranslate = () => {
  const { t: translate } = useTranslation();

  const t = (firstKey, secondKey) => {
    if (typeof firstKey === 'string') {
      return translate(firstKey, secondKey ?? { value: '' });
    }
    if (Array.isArray(firstKey)) {
      return translate(firstKey[0], { value: translate(firstKey[1]) });
    }
    return translate(firstKey, { value: translate(secondKey) });
  };

  // display value and show default if value not avail
  const d = (
    value,
    defaultValue = 'n/a',
    { translateValue, translateDefaultValue } = {
      translateDefaultValue: true,
      translateValue: false,
    },
  ) => {
    const tempValue = translateValue ? t(value) : value;
    const tempDefaultValue = translateDefaultValue
      ? t(defaultValue)
      : defaultValue;

    return value ? tempValue : tempDefaultValue;
  };

  // display value if match condition else show default value
  const dc = (
    condition,
    value,
    defaultValue = 'n/a',
    { translateValue, translateDefaultValue } = {
      translateDefaultValue: true,
      translateValue: false,
    },
  ) => {
    const tempValue = translateValue ? t(value) : value;
    const tempDefaultValue = translateDefaultValue
      ? t(defaultValue)
      : defaultValue;

    return condition ? tempValue : tempDefaultValue;
  };

  return { t, d, dc };
};
export default useTranslate;
