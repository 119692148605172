import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CandidateList from 'src/components/candidate';

const CandidateListPage = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <CandidateList />
    </FormProvider>
  );
};

export default CandidateListPage;
