import { Navigate, Outlet } from 'react-router';
import DashboardLayout from 'src/layouts/dashboard';
import PrivateRoute from 'src/components/private-route';
import React, { lazy, Suspense } from 'react';
import { LinearProgress } from '@mui/material';
import { AccessControlRoute } from 'src/components/access-control';
import CreateJob from 'src/pages/job/create';

import CreateCompany from 'src/pages/company/create';
import JobDetail from 'src/pages/job/job-detail';
import CandidateList from 'src/pages/candidate';
import CreateCandidate from 'src/pages/candidate/create';
import CompanyPage from 'src/pages/company';

// lazy loading
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const JobListPage = lazy(() => import('src/pages/job'));
const UserPage = lazy(() => import('src/pages/setting/user'));
const RolePermissionSettingPage = lazy(() =>
  import('src/pages/setting/role-permission'),
);
const ProfilePage = lazy(() => import('src/pages/setting/profile'));

export const ROUTE_PATH = {
  REGISTER: '/auth/register',
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  DASHBOARD: '/dashboard',
  PROFILE: '/dashboard/setting/profile',
  CREATE_JOB: '/dashboard/job/create',
  JOB_DETAIL: '/dashboard/job/detail',
  JOB: '/dashboard/job',
  COMPANY: '/company',
  CREATE_COMPANY: '/company/create',
  CREATE_CANDIDATE: '/dashboard/candidate/create',
  CANDIDATE: '/dashboard/candidate',
};

const ROUTES_PATH = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <PrivateRoute auth={true} redirect='/dashboard'>
            <Suspense fallback={<LinearProgress />}>
              <LoginPage />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'register',
        element: (
          <PrivateRoute auth={true} redirect={ROUTE_PATH.DASHBOARD}>
            <Suspense fallback={<LinearProgress />}>
              <RegisterPage />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'invite/:token',
        element: (
          <Suspense fallback={<LinearProgress />}>
            <RegisterPage />
          </Suspense>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <PrivateRoute auth={true} redirect={ROUTE_PATH.DASHBOARD}>
            <Suspense fallback={<LinearProgress />}>
              <ForgotPasswordPage />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  // PRIVATE ROUTE
  {
    path: 'company',
    element: (
      <PrivateRoute auth={false} isCompleted={false}>
        <Suspense fallback={<LinearProgress />}>
          <DashboardLayout showDrawer={false}>
            <CreateCompany />
          </DashboardLayout>
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <PrivateRoute auth={false} redirect='/login'>
        <DashboardLayout>
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </PrivateRoute>
    ),
    children: [
      { index: true, element: null },
      // {
      //   path: 'department',
      //   element: (
      //     <AccessControlRoute execute='view' feature='department'>
      //       <DepartmentListPage />
      //     </AccessControlRoute>
      //   ),
      // },
      {
        path: 'job',
        children: [
          {
            index: true,
            element: (
              <AccessControlRoute execute='view' feature='job'>
                <JobListPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'create/:id?',
            element: (
              <AccessControlRoute execute='view' feature='job'>
                <CreateJob />
              </AccessControlRoute>
            ),
          },
          {
            path: 'detail/:id?',
            element: (
              <AccessControlRoute execute='view' feature='job'>
                <JobDetail />
              </AccessControlRoute>
            ),
          },
        ],
      },
      {
        path: 'company',
        children: [
          {
            index: true,
            element: (
              <AccessControlRoute execute='view' feature='company'>
                <CompanyPage />
              </AccessControlRoute>
            ),
          },
        ],
      },
      {
        path: 'candidate',
        children: [
          {
            index: true,
            element: (
              <AccessControlRoute execute='view' feature='candidate'>
                <CandidateList />
              </AccessControlRoute>
            ),
          },
          {
            path: 'create/:id?',
            element: (
              <AccessControlRoute execute='view' feature='candidate'>
                <CreateCandidate />
              </AccessControlRoute>
            ),
          },
        ],
      },
      {
        path: 'setting',
        children: [
          {
            path: 'role-permission',
            element: (
              <AccessControlRoute execute='view' feature='role-permission'>
                <RolePermissionSettingPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'user',
            element: (
              <AccessControlRoute execute='view' feature='user'>
                <UserPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
        ],
      },
      { path: '*', element: <Navigate to='/dashboard' /> },
    ],
  },
  { path: '*', element: <Navigate to='auth/login' /> },
];

export default ROUTES_PATH;
