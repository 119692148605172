import axios from 'axios';

const CompanyAPI = {
  get: () => {
    return axios.get('company');
  },
  update: (payload) => {
    return axios.put('auth/update-workspace', payload);
  },
  isActive: (id, payload) => {
    return axios.put(`company/isActive/${id}`, payload);
  },
  url: 'company/get-all',
};

export default CompanyAPI;
