import React from 'react';
import { Grid } from '@mui/material';
import TitleContent from '../title-content';
import ControlledCKEditor from '../controlled-input/ckeditor';

const JobRequirement = () => {
  return (
    <Grid container spacing={3} display='flex' justifyContent='center'>
      <Grid item xs={12} textAlign='center'>
        <TitleContent title='requirement-and-benefits' />
      </Grid>
      <Grid item md={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledCKEditor name='requirement' height={'600px'} required />
          </Grid>
          <Grid item xs={12}>
            <ControlledCKEditor name='benefit' height={'600px'} required />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobRequirement;
