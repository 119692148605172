import React from 'react';
import { Select, MenuItem, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/system';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const PaginationX = ({
  totalActive,
  page,
  onChangePagination,
  onChangeRowsPerPage,
  count,
  total,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '50px',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            margin: '0 5px',
          },
          '& .MuiSelect-outlined.MuiSelect-outlined': {
            padding: '5px 28px 5px 10px !important',
          },
          '& p': {
            fontSize: '14px',
          },
        }}
      >
        <Typography>Show </Typography>
        <Select
          variant='outlined'
          value={rowsPerPage}
          onChange={onChangeRowsPerPage}
        >
          {rowsPerPageOptions.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          ))}
        </Select>
        <Typography> of {total} entries</Typography>
        <Typography display='flex' ml={3}>
          <FiberManualRecordIcon fontSize='small' color='primary' />
          {totalActive}
          {' on'}
        </Typography>
      </Box>
      <Pagination
        shape='rounded'
        color='primary'
        page={page}
        onChange={onChangePagination}
        count={count}
      />
    </Box>
  );
};

export default PaginationX;
