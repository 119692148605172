import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { useAuth } from 'src/context/AuthProvider';

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  return {
    children: `${
      name?.split(' ').length > 1
        ? name?.split(' ')[0][0] + name?.split(' ')[1][0] || 'N/A'
        : name?.split(' ')[0][0] + name?.split(' ')[0][1] || 'N/A'
    }`,
  };
};

const Logo = () => {
  const { user } = useAuth();

  return (
    <Grid
      container
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        backgroundColor: (theme) => theme.palette.drawer.background,
        height: '100px',
      }}
    >
      <Grid item>
        <Avatar
          src={user?.workspace.image.key}
          alt='thumbnail-company'
          sx={{
            backgroundColor: stringToColor(user?.workspace.name),
            '& img': { objectFit: 'contain' },
            width: 54,
            height: 54,
          }}
          {...stringAvatar(user?.workspace.name)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='h2' ml={2} sx={{ whiteSpace: 'normal' }}>
          {user?.workspace.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Logo;
