import {
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
  Typography,
} from '@mui/material';
import Checkbox from '../checkbox';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TableHeadX = ({
  select,
  selected,
  schema,
  handleSort,
  sort,
  handleSelectMultiple,
  dataList,
}) => {
  const createSortHandler = (property) => (event) => {
    handleSort(event, property);
  };

  return (
    <TableHead
      sx={{ backgroundColor: (theme) => theme.palette.secondary.main }}
    >
      <TableRow>
        {select && (
          <TableCell>
            <Checkbox
              indeterminate={
                selected.length > 0 && selected.length < dataList.length
              }
              checked={
                selected.length > 0 && selected.length === dataList.length
              }
              onClick={handleSelectMultiple}
            ></Checkbox>
          </TableCell>
        )}
        {schema.map((item, index) => (
          <TableCell
            key={index}
            width={item.width}
            align={item.headerAlign || 'left'}
          >
            {item.sort === false ? (
              <Typography variant='h2'>{item.label} </Typography>
            ) : (
              <TableSortLabel
                IconComponent={KeyboardArrowDownIcon}
                active={item.id in sort}
                direction={item.id in sort ? sort[item.id] : 'asc'}
                onClick={createSortHandler(item.id)}
              >
                <Typography variant='h2'> {item.label} </Typography>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadX;
