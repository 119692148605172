import imageCompression from 'browser-image-compression';

export const addRemoveImageListener = (editor, callback) => {
  editor.model.document.on('change:data', (event) => {
    const elementTypes = ['image', 'imageBlock', 'imageInline'];

    const differ = event.source.differ;

    // if no difference
    if (differ.isEmpty) {
      return;
    }

    const changes = differ.getChanges({
      includeChangesInGraveyard: true,
    });

    if (changes.length === 0) {
      return;
    }

    let hasNoImageRemoved = true;

    // check any image remove or not
    for (let i = 0; i < changes.length; i++) {
      const change = changes[i];
      // if image remove exists
      if (
        change &&
        change.type === 'remove' &&
        elementTypes.includes(change.name)
      ) {
        hasNoImageRemoved = false;
        break;
      }
    }

    // if not image remove stop execution
    if (hasNoImageRemoved) {
      return;
    }

    // get removed nodes
    const removedNodes = changes.filter(
      (change) =>
        change.type === 'insert' && elementTypes.includes(change.name),
    );

    // removed images src
    const removedImagesSrc = [];

    removedNodes.forEach((node) => {
      const removedNode = node.position.nodeAfter;
      const imgSrc = removedNode.getAttribute('src');
      if (imgSrc) {
        removedImagesSrc.push(imgSrc);
      }
    });

    // invoke the callback
    callback(removedImagesSrc);
  });
};

const options = {
  maxSizeMB: 5,
  maxWidthOrHeight: 500,
  useWebWorker: true,
};

export const addCustomImageUploader = (editor, callback) => {
  editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then(async (file) => {
              const compressedImage = await imageCompression(file, options);
              const blobImage = URL.createObjectURL(compressedImage);
              callback({ blob: blobImage, file: compressedImage });
              resolve({ default: blobImage });
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    };
  };
};

export const filterImageType = (images) => {
  const { removeUploadedImages, removeLocalImages } = images.reduce(
    (acc, curr) => {
      if (curr.includes('blob:')) {
        acc.removeLocalImages.push(curr);
      } else {
        acc.removeUploadedImages.push({
          key: curr,
        });
      }
      return acc;
    },
    { removeUploadedImages: [], removeLocalImages: [] },
  );
  return { removeLocalImages, removeUploadedImages };
};

export const filterRemoveLocalImage = (allImages, removeImages) => {
  return allImages.filter((image) => {
    return removeImages.some((item) => {
      return item !== image.blob;
    });
  });
};
