import React, { useState } from 'react';
import { Box, Backdrop, CircularProgress, Paper } from '@mui/material';

import useUI from 'src/hooks/useUI';
import Navbar from './navbar';
import Drawer from './drawer';

const DashboardLayout = ({ children, showDrawer = true }) => {
  const [open, setOpen] = useState(true);
  const toggle = () => {
    setOpen((prev) => !prev);
  };

  // Check for some route that doesn't need layout

  const { isLoading } = useUI();

  return (
    <Box display='flex' height='100vh' overflow='hidden'>
      <Backdrop sx={{ zIndex: 1202 }} open={isLoading}>
        <CircularProgress color='primary' thickness={5} size={70} />
      </Backdrop>
      {showDrawer ? <Drawer open={open} /> : null}

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          height: '100%',
          overflow: 'overlay',
          background: '#FAF0F0',
          display: 'flex',
          paddingTop: '120px',
          direction: 'column',
          position: 'relative',
        }}
      >
        <Navbar open={open} toggle={toggle} />
        <Paper
          sx={{
            width: '100%',
            overflow: 'overlay',
            px: 5,
            py: 1,
          }}
          elevation={1}
        >
          {children}
        </Paper>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
